import React from "react";
import Button from "./Button";
import { makeStyles } from "@material-ui/core";
import Colors from "../../varaibles/colors";

const useStyles = makeStyles(() => ({
    button: {
        '&:hover': {
            backgroundColor: Colors.deloitte_green
          },
          textTransform: 'none',
          '&:focus': {
            outline: 'none'
          }
    }
}))

const ButtonContainer = ({...props}) => {
    const classes = useStyles();

    return <Button {...props} classes={classes} />
}

export default React.memo(ButtonContainer);