import React, { useState } from "react";

import NewGroupView from './NewGroupView'
import UpdateGroupView from './UpdateGroupView'
import AccessGroupsView from './AccessGroupsView'

const GroupsContainerView = () => {

  const handelSelectedView = (chosenView, rolesList , usersList, groupID, groupName) => {
    console.log(rolesList)
    switch (chosenView) {
      case "NewGroup":
        setSelectedView(<NewGroupView handelSelectedView={handelSelectedView} />);
        break;

      case "UpdateGroup":
        setSelectedView(<UpdateGroupView handelSelectedView={handelSelectedView} usersList={usersList} rolesList={rolesList} groupID={groupID} groupName={groupName} />);
        break;

      case "all":
        setSelectedView(<AccessGroupsView handelSelectedView={handelSelectedView} />);
        break;


    }

  }
  const [selectedView, setSelectedView] = useState(<AccessGroupsView handelSelectedView={handelSelectedView} />);

  return (
    <div>
      {
        selectedView
      }
    </div>
  );
}


export default GroupsContainerView;