import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    backdrop: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: 1000,
        // left: 0,
        // top: 0,
        backgroundColor: '#D3D3D3',
        opacity: .4,
    },
    circular: {
        zIndex: 1001,
        position: 'fixed',
        top: '45%',
        right: '-50%',
    },
    show:{
        display:'block'
    },
    hide:{
        display:'none'
    }
}));

const Loader = (props) => {
    const classes = useStyles();

    return (
        <div className={props.showLoader ? classes.show : classes.hide  }>
            <div className={classes.backdrop}>
            </div>
            <CircularProgress  className={classes.circular}/>
        </div>
    );
}


export default  Loader;