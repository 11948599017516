import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Colors from '../varaibles/colors'

const AlertDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.alertOpen}
                onClose={props.handelAlertView}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle id="responsive-dialog-title">{props.alertMessage}</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        Let Google help apps determine location. This means sending anonymous location data to
                        Google, even when no apps are running.
                    </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handelAlertClose} variant="contained" style={{ background: Colors.deloitte_green, marginLeft: '1%', color: 'white' }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default AlertDialog;