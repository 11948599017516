import React from "react";
import Sidebar from "./Sidebar";


const SidebarContainer = ({children, className}) => {


    return <Sidebar children={children} className={className} />
}

export default React.memo(SidebarContainer);