import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import API from '../../varaibles/baseApi';
import { Paper, Button, Table, Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AlertDialog from '../../components/AlertDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
import Loader from '../../components/Loader';

import TextInput from "../../components/input/input";

import axios from 'axios';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function createOptionsData(rowID, dropdownList, option) {
    return { rowID, dropdownList, option };
}

const headCells = [
    {
        id: "DropdownList",
        numeric: false,
        disablePadding: true,
        label: "Dropdown List",
    },
    {
        id: "option",
        numeric: true,
        disablePadding: false,
        label: "Options",
    },
    {
        id: "",
        numeric: true,
        disablePadding: false,
        label: "",
    },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHeaderStyle}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding="default"
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ fontWeight: 'bold' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >

                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {

    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        boxShadow: 'none',
    },
    inputPaper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
        '& tr:nth-child(even)': {
            backgroundColor: '#fafafa',
        },
        '& tr:hover': {
            backgroundColor: '#fafafa',
        }
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    tableHeaderStyle: {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        background: '#fafafa'
    },
    TablePaginationStyle: {
        "& p:first-of-type": {
            display: "none",
        },
        "& .MuiTablePagination-selectRoot": {
            display: "none",
        },
    },
    tableContainer: {
        maxHeight: 260,
    },
    removeBtn: {
        background: '#b40100',
        textTransform: 'none',
        color: 'white'
    },
    transactionActionsContainer: {
        textAlign: 'right',
        marginTop: '3%'
    },
    button: {
        lineHeight: 1.43,
        width: '22%',
        color: "#005074",
        borderColor: "#005074",
        textTransform: "none",
        fontWeight: 'bold',
        fontFamily: 'Montserrat'
    },
    gridContainer: {
        // justifyContent: "space-between",
        width: '100%'
    },
    sectionHeader: {
        textAlign: 'left',
        marginBottom: '1%'
    },
    sectionHeadTitle: {
        textAlign: 'left',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: '1%',
        display: 'inline-block'
    },
    sectionButton: {
        width: 'auto',
        color: "#005074",
        borderColor: "#005074",
        textTransform: "none",
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        padding: '0px 24px',
        marginLeft: '2%'
    },
}));


const UpdateDropListView = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const [alertMessage, setAlertMessage] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);

    const [showLoader, setShowLoader] = useState(false);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("DropdownList");

    const [confirmMessage, setConfirmMessage] = useState("");
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [removeSuccess, setRemoveItemSuccess] = useState();

    const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false);

    const [optionInputVal, setOptionInputVal] = useState("");
    const [optionInputError, setOptionInputError] = useState(false);

    const [optionsList, setOptionsList] = useState([]);


    const onChangeOptionInputHandler = (event, inputType) => {

        switch (inputType) {
            case 'optionInput':
                setOptionInputVal(event.target.value);
                if (event.target.value) {
                    setOptionInputError(false)
                } else {
                    setOptionInputError(true)
                }
                break;
        }
    }



    useEffect(() => {
        console.log(props.dropDownName);
        axios
        .get(
            "" + API.baseURL + "Configurations/dropdownListByName?DropdownName="+props.dropDownName+""
        )
        .then((response) => {
            console.log(response.data);
            let dropList = response.data.map((dropList) =>
                createOptionsData(
                    dropList.optionId,
                    props.dropDownName,
                    dropList.optionTitle,
                )
            );
            setOptionsList(dropList);
        });
    }, []);


    const handelAlertView = () => {
        setAlertOpen(!alertOpen);
    }

    const handelAlertClose = () => {
        setAlertOpen(!alertOpen);
        if (updatedSuccessfully) {
            props.handelSelectedView("all")
        }
    }

    const handelDisagreeConfirm = () => {
        setConfirmOpen(!confirmOpen);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const addOptionsHandler = () => {
        if (!optionInputVal) {
            setOptionInputError(true);
        } else {
            setOptionInputError(false);
        }

        if (optionInputVal) {
            let rowID = (new Date()).toString().replace(/ /g, "");

            setOptionInputVal('');
            let addedOption = {
                rowID: rowID,
                dropdownList:props.dropDownName,
                option:optionInputVal
            }
            // console.log(productObject); 
            setOptionsList([addedOption].concat(optionsList));

        }
    };


    const removeOptionItem = (OptionID) => {
        setConfirmOpen(true);
        setConfirmMessage("Do you really want to delete this option?");

        const itemsDeletedList = () => {
            let updatedOptionsList = optionsList.filter(item => item.rowID !== OptionID);
            setOptionsList(updatedOptionsList);
            setConfirmOpen(false);
        }
        setRemoveItemSuccess(() => itemsDeletedList);
    }


    const submitDropdown = () => {
            console.log(props.dropDownName);
            console.log(optionsList);

            if (optionsList.length > 0) {
                setShowLoader(true);

                axios({
                    method: 'post',
                    url: "" + API.baseURL + "Configurations/addDropdownOptions?tableName="+props.dropDownName+"",
                    data: optionsList,
                          // optionsList: optionsList,
                        // tableName: props.dropDownName,
                    

                }).then(function (response) {
                    console.log(response);
                    setShowLoader(false);
                    setAlertOpen(true);
                    setAlertMessage("Updated Successfully");
                    setUpdatedSuccessfully(true);

                }).catch(function (error) {
                    console.log(error);
                });


            } else {
                setAlertOpen(true);
                setAlertMessage("You Need To Add Options First");
            }
    }

    var optionErrorPaperStyle = (optionInputError ? { border: '1px solid red' } : { border: 'none' });
   
    return (
        <div>
            <Loader showLoader={showLoader} />

            <AlertDialog handelAlertView={handelAlertView} handelAlertClose={handelAlertClose} alertOpen={alertOpen} alertMessage={alertMessage} />

            <ConfirmDialog handelDisagreeConfirm={handelDisagreeConfirm} handelAgreeConfirm={removeSuccess} confirmOpen={confirmOpen} confirmMessage={confirmMessage} />

            <Grid className={classes.gridContainer} container spacing={1}>

                <Grid item style={{ width: "30%", lineHeight: 0 }}>
                    <Paper className={classes.paper} style={optionErrorPaperStyle}>
                        <TextInput
                            onChange={(event) => onChangeOptionInputHandler(event, 'optionInput')}
                            type="text"
                            placeholder="Dropdown List Option"
                            value={optionInputVal}
                        />
                    </Paper>
                    {optionInputError ? (
                        <div className={classes.errorMessage}>Option is required</div>
                    ) : (
                            ""
                        )}
                </Grid>

                <Grid item style={{ width: "30%" }}>
                    <Button
                        style={{ width: "100%" }}
                        variant="outlined"
                        className={classes.button}
                        onClick={addOptionsHandler}
                    >
                        Add Options List
                </Button>
                </Grid>
            </Grid>

            <Paper className={classes.paper} style={{padding: '0px',marginTop: '2%'}}>
                <TableContainer className={classes.tableContainer}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        stickyHeader aria-label="sticky table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        {optionsList.length > 0 ? (
                            <TableBody>
                                {stableSort(optionsList, getComparator(order, orderBy))
                                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.rowID}
                                            >
                                                <TableCell> {row.dropdownList} </TableCell>
                                                <TableCell>{row.option}</TableCell>
                                                <TableCell>
                                                    <Button disabled={optionsList.length == 1 ? true : false} variant="contained" onClick={() => removeOptionItem(row.rowID)} size="small" className={classes.removeBtn} >
                                                        Remove
                                                    </Button>
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })}
                            </TableBody>

                        )
                            : (
                                <TableBody>
                                    <TableRow >
                                        <TableCell colSpan={4} style={{ textAlign: 'center' }} > There Are No Expenses Yet, Try To Add Some </TableCell>
                                    </TableRow>
                                </TableBody>
                            )
                        }
                    </Table>
                </TableContainer>
            </Paper>


            <div className={classes.transactionActionsContainer}>
                <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={() => props.handelSelectedView("all")}
                >
                    Close
                </Button>

                <Button
                    variant="contained"
                    className={classes.button}
                    style={{ background: '#005074', marginLeft: '1%', color: 'white' }}
                    onClick={submitDropdown}
                >
                    Update Dropdown List
                </Button>
            </div>

        </div>
    );
}


export default UpdateDropListView;
