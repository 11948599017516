import React from "react";

const Sidebar = ({ children, className }) => {
    return <div className={className}>{
        children
    }</div>
}



export default React.memo(Sidebar);