import React from "react";
import InputBase from '@material-ui/core/InputBase';
// import { Search as SearchIcon } from "@material-ui/icons";
import { Button } from "../../components/Button";
import clsx from 'clsx';

import SearchIcon from '../../assets/icons/search.svg';

const Search = ({classes, searchInputValue, onChangeSearchHandler, className}) => {
    return (
        <div className={clsx(classes.search, className)}>
            <div className={clsx(classes.searchIcon, 'col-1')}>
                <img src={SearchIcon} />
            </div>
            <InputBase
                value={searchInputValue}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                className="col-9"
                inputProps={{ 'aria-label': 'search', className: 'w-100' }}
                onChange={onChangeSearchHandler}
            />
            <Button className={clsx(classes.searchButton, 'col-3')}>Search</Button>
        </div>
    )
}


export default React.memo(Search)