import React from "react";
import SectionHeader from "./SectionHeader";
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles(()=>({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '30px'
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    addImg: {
        cursor: 'pointer'
    },
    exportImg: {},
    importImg: {},
    deleteImg: {},
}))

const SectionHeaderContainer = ({...props}) => {

    const classes = useStyles();

    return <SectionHeader classes={classes} {...props}/>
}

export default React.memo(SectionHeaderContainer)