import React, { useState } from "react";

import NewGroupView from './NewGroupView'
import UpdateDropListView from './UpdateDropListView'
import DropListView from './DropListView'

const DropListContainerView = () => {

  const handelSelectedView = (chosenView, dropDownName) => {
    console.log(dropDownName)
    switch (chosenView) {

      case "Edit":
        setSelectedView(<UpdateDropListView handelSelectedView={handelSelectedView} dropDownName={dropDownName} />);
        break;

      case "all":
        setSelectedView(<DropListView handelSelectedView={handelSelectedView} />);
        break;


    }

  }
  const [selectedView, setSelectedView] = useState(<DropListView handelSelectedView={handelSelectedView} />);

  return (
    <div>
      {
        selectedView
      }
    </div>
  );
}


export default DropListContainerView;