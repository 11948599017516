import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ConfigurationsTabs from '../../components/ConfigurationsTabs/ConfigurationsTabs'

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
 
}));

const Configuration = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ConfigurationsTabs />
    </div>
  );
};

export default Configuration;
