import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import API from "../../varaibles/baseApi";
import AlertDialog from '../AlertDialog';
import Colors from "../../varaibles/colors";
import { Formik, Form, Field, ErrorMessage } from "formik";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "2%",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  },
  gridContainer: {
    justifyContent: "flex-end",
    width: "100%",
    borderRadius: "15px",
    padding: "15px",
    background: "#fff",
  },
  select: {
    border: "none",
    outline: "none",
    width: "100%",
  },
  button: {
    lineHeight: 1.43,
    width: "100%",
    padding: "7px",
    color: "#005074",
    borderColor: "#005074",
    textTransform: "none",
    fontWeight: "bold",
  },
  buttonFilled: {
    lineHeight: 1.43,
    width: "100%",
    padding: "7px",
    color: "white",
    background: "#005074",
    // borderColor: "#005074",
    textTransform: "none",
  },
  errorMessage: {
    textAlign: "left",
    marginLeft: "4px",
    color: "red",
    fontSize: "12px",
  },
  textField: {
    width: '100%',
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
  },
  inputLabel: {
    marginBottom: "2%",
    // fontWeight: 700,
    textAlign: "left",
  },
  outlinedInputStyle: { background: "white", width: "100%", height: '41px', borderRadius: '10px' },
  selectInputPaper: {
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
    height: "41px",
    width: "99%",
    borderRadius: '10px'
  },
  createEntitiesActionButtons: { textTransform: 'none', width: '10%', height: '41px', borderRadius: '10px' },
  addButton: {
    backgroundColor: Colors.deloitte_green,
    color: Colors.deloitte_green,
  },
  cancelButton: {
    backgroundColor: '#fff',
    color: Colors.deloitte_green,
    borderColor: Colors.deloitte_green
  }

}));

const CreateFactor = (props) => {
  const classes = useStyles();
  const [showLoader, setShowLoader] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("");
  const [parametersList, setParametersList] = useState([]);
  const [selectedParameterId, setSelectedParameterId] = useState();

  const handelAlertView = () => {
    setAlertOpen(!alertOpen);
  }

  const handelAlertClose = () => {
    setAlertOpen(!alertOpen);
  }


  const onChangeInputHandler = (parameterId) => {
    if (parameterId != selectedParameterId) {
      axios
        .get(
          `${API.baseURL}Parameters/LockupConditionByParameterId/${parameterId}`
        )
        .then((response) => {
          setSelectedCondition(response.data);
          setSelectedParameterId(parameterId);
        })
        .catch((error) => setSelectedCondition([]));
    }
  };

  useEffect(() => {
    onChangeInputHandler(selectedParameterId);
    axios
      .get("" + API.baseURL + "Parameters/LockupParameterWithCondition")
      .then((response) => {
        setParametersList(response.data);
      });
  }, []);

  const validate = (values) => {
    onChangeInputHandler(values.selectedParameterId);
    const errors = {};
    if (values.selectedParameterId === "") {
      errors.selectedParameterId = "Parameter is required";
    }
    if (values.factorName === "") {
      errors.factorName = "Factor Name is required";
    }
    if (values.factorScore === "") {
      errors.factorScore = "Factor Score is required";
    }
    if (values.note === "") {
      errors.note = "Note is required";
    }
    if (values.valueOne === "") {
      errors.valueOne = "Value 1 required";
    }
    return errors;
  };

  const onSubmit = (values, { resetForm }) => {
    axios({
      method: "post",
      url: "" + API.baseURL + "Factors/AddFactors",
      data: {
        factorName: String(values.factorName),
        factorScore: parseInt(values.factorScore),
        note: String(values.note),
        value1: String(values.valueOne),
        value2: String(values.valueTwo),
        parameterId: parseInt(values.selectedParameterId),
        conditionId: parseInt(selectedCondition.id),
      },
    })
      .then(function (response) {
        setShowLoader(false);
        setAlertOpen(true);
        setAlertMessage("Factor Added Successfully");
        props.loadAllFactors();
      })
      .catch(function (error) {
        setShowLoader(false);
      });
    resetForm((values = {}));
    // props.onHideCreatFormHandler();
  };
  return (
    <div className={classes.root}>
      <AlertDialog handelAlertView={handelAlertView} handelAlertClose={handelAlertClose} alertOpen={alertOpen} alertMessage={alertMessage} />
      <Formik
        validate={validate}
        onSubmit={onSubmit}
        initialValues={{
          selectedParameterId: 0,
          condition: selectedCondition,
          factorName: "",
          factorScore: "",
          note: "",
          valueOne: "",
          valueTwo: "",
        }}
      >
        {() => (
          <Form>
            <div className={classes.gridContainer}>
              <Grid style={{ marginBottom: '15px' }} container spacing={1}>
                <Grid item style={{ width: "25%" }}>
                  <div className={classes.inputLabel}>Parameter List</div>
                  <Field
                    as="Select"
                    name="selectedParameterId"
                    className="form-control"
                  // onChange={(e) => {
                  //   onChangeInputHandler(e);
                  // }}
                  >
                    <option defaultValue value="">
                      Parameter List
                    </option>
                    {parametersList.map((parameter) => (
                      <option key={parameter.id} value={parameter.id}>
                        {parameter.parameterName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="parameterList"
                    component="div"
                    className={classes.errorMessage}
                  ></ErrorMessage>
                </Grid>

                <Grid item style={{ width: "25%" }}>
                  <div className={classes.inputLabel}>Condition</div>
                  <Field
                    disabled
                    as="Select"
                    name="condition"
                    className="form-control"
                  >
                    <option
                      value={selectedCondition.id}
                      data-id={selectedCondition.conditionId}
                      key={selectedCondition.id}
                    >
                      {selectedCondition.conditionName}
                    </option>
                  </Field>
                </Grid>

                <Grid item style={{ width: "25%" }}></Grid>

                <Grid item style={{ width: "25%" }}></Grid>
              </Grid>


              <Grid style={{ marginBottom: '15px' }} container spacing={1}>
                <Grid item style={{ width: "25%" }}>
                  <div className={classes.inputLabel}>Factor Name</div>
                  <Field
                    className="form-control"
                    type="text"
                    name="factorName"
                  ></Field>
                  <ErrorMessage
                    name="factorName"
                    component="div"
                    className={classes.errorMessage}
                  ></ErrorMessage>
                </Grid>

                <Grid item style={{ width: "25%" }}>
                  <div className={classes.inputLabel}>Factor Score</div>
                  <Field
                    className="form-control"
                    type="text"
                    name="factorScore"
                  ></Field>
                  <ErrorMessage
                    name="factorScore"
                    component="div"
                    className={classes.errorMessage}
                  ></ErrorMessage>
                </Grid>

                <Grid item style={{ width: "25%" }}>
                  <div className={classes.inputLabel}>Note</div>
                  <Field className="form-control" type="text" name="note"></Field>
                  <ErrorMessage
                    name="note"
                    component="div"
                    className={classes.errorMessage}
                  ></ErrorMessage>
                </Grid>

                <Grid item style={{ width: "25%" }}>
                  <div className={classes.inputLabel}>Value 1</div>
                  <Field
                    className="form-control"
                    type="text"
                    name="valueOne"
                  ></Field>
                  <ErrorMessage
                    name="valueOne"
                    component="div"
                    className={classes.errorMessage}
                  ></ErrorMessage>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item style={{ width: "25%" }}>
                  <div className={classes.inputLabel}>Value 2</div>
                  <Field
                    className="form-control"
                    type="text"
                    name="valueTwo"
                  ></Field>
                  <ErrorMessage
                    name="valueTwo"
                    component="div"
                    className={classes.errorMessage}
                  ></ErrorMessage>
                </Grid>

                <Grid item style={{ width: "25%" }}></Grid>

                <Grid item style={{ width: "25%" }}></Grid>

                {/* <Grid
                  item
                  style={{
                    width: "25%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "2%",
                  }}
                >
                  <Button
                    variant="contained"
                    className={classes.createEntitiesActionButtons}
                    onClick={props.onHideCreatFormHandler}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className={classes.createEntitiesActionButtons}
                    style={{ background: Colors.deloitte_green, color: "white" }}
                  >
                    Save
                  </Button>
                </Grid> */}
                <Grid item style={{ width: "100%", display: 'flex', justifyContent: 'start', marginTop: '2%' }}>
                  <Button variant="outlined" className={clsx(classes.createEntitiesActionButtons, classes.cancelButton, 'mx-2')} onClick={props.onHideCreatFormHandler}>Cancel</Button>
                  <Button variant="contained" type="submit" className={clsx(classes.createEntitiesActionButtons, classes.addButton, 'mx-2')} style={{ background: Colors.deloitte_green, color: 'white' }}>
                    Add
                  </Button>
                </Grid>

              </Grid>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateFactor;
