import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { makeStyles, useTheme, fade } from '@material-ui/core/styles';
import CreateEntities from '../../components/CreateEntities/CreateEntities'
import UpdateEntities from '../../components/CreateEntities/UpdateEntities'
import API from '../../varaibles/baseApi';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Checkbox } from '@material-ui/core';
import AlertDialog from '../../components/AlertDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
import Loader from '../../components/Loader';
import Colors from '../../varaibles/colors'


import { Search } from "../../components/Search";

import { SectionHeader } from '../../components/SectionHeader';

import Delete from '../../assets/icons/delete_red.svg';
import Edit from '../../assets/icons/edit.svg';
import clsx from 'clsx'

function createListData(Id, Name, Code, Country, City, CountryId, CityId) {
  return { Id, Name, Code, Country, City, CountryId, CityId };
}

const headCells = [
  {
    id: "Id",
    numeric: false,
    disablePadding: true,
    label: "Id",
  },
  {
    id: "Name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "Code",
    numeric: true,
    disablePadding: false,
    label: "Code",
  },
  {
    id: "Country",
    numeric: true,
    disablePadding: false,
    label: "Country",
  },
  { id: "City", numeric: true, disablePadding: false, label: "City" },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];


function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeaderStyle}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="default"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: 'bold' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  baseRootPageStyle: {
    // background: 'white',
    // padding: '15px',
    // borderRadius: '15px',
    // boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.04)',
    // marginTop: '50px',
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    borderRadius: 0,
  },
  table: {
    minWidth: 750,
    '& tr:nth-child(even)': {
      backgroundColor: '#fafafa',
    },
    '& tr:hover': {
      backgroundColor: '#fafafa',
    },
    '& tbody .MuiTableRow-root.Mui-selected': {
      backgroundColor: Colors.lightYellow
    },
    '& tbody .MuiCheckbox-colorSecondary.Mui-checked': {
      color: Colors.yellow
    },
    padding: '20px'
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableHeaderStyle: {
    // borderBottom: "1px solid rgba(224, 224, 224, 1)",
    background: '#fff',

  },
  TablePaginationStyle: {
    "& p:first-of-type": {
      display: "none",
    },
    "& .MuiTablePagination-selectRoot": {
      display: "none",
    },
  },
  sizeCellContainer: {
    textAlign: 'center',
    background: '#8498c9',
    color: 'white',
    padding: '4px',
    width: '70%',
    borderRadius: '4px',
  },
  tableContainer: {
    maxHeight: 260,
  },
  sectionTitle: {
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  removeBtn: {
    background: '#b40100',
    textTransform: 'none',
    color: 'white'
  },
  transactionActionsContainer: {
    textAlign: 'right'
  },
  button: {
    lineHeight: 1.43,
    width: '20%',
    color: "#005074",
    borderColor: "#005074",
    textTransform: "none",
    fontWeight: 'bold',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    border: '1px solid darkgrey',
    borderRadius: '12px'
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.black
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  menuIconStyle: {
    width: '16%',
    filter: 'brightness(0)'
  },
  tableHeaderContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2%' },
  actions: {
    cursor: 'pointer'
  }
}));


const Entities = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [selectedRow, setSelectedRow] = useState([]);


  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [entitiesList, setEntitiesList] = useState([]);

  const [newProduct, setNewProduct] = useState();

  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeSuccess, setRemoveItemSuccess] = useState();


  const [anchorEl, setAnchorEl] = useState(null);
  const [moreRowEl, setMoreRowEl] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [updateForm, setUpdateForm] = useState(null);
  const [editMoodForm, setEditMoodForm] = useState(false);
  const [multipleDelete, setMultipleDelete] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  const loadAllEntities = () => {
    axios
      .get("" + API.baseURL + "entities/apiEntities")
      .then((response) => {
        let entitiesList = response.data.map((entity) => (
          createListData(
            entity.id,
            entity.name,
            entity.code,
            entity.country?.countryName,
            entity.city?.cityName,
            entity.countryId,
            entity.cityId,
          )
        ));
        setEntitiesList(entitiesList);
      });
  }

  useEffect(() => {
    loadAllEntities();
  }, []);


  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onMenuAddHandler = () => {
    handleMenuClose();
    setShowCreateForm(!showCreateForm);
    setUpdateForm(null);
  };

  const onHideCreatFormHandler = () => {
    setShowCreateForm(false);
  };


  const handleRowMenuClick = (event, rowData) => {
    setMoreRowEl(event.currentTarget);
    setSelectedRowData(rowData);
  };


  const handleRowMenuClose = () => {
    setMoreRowEl(null);
  };

  const onMenuMultipleDelete = () => {
    handleMenuClose();
    setConfirmOpen(true);
    setConfirmMessage("Do you really want to delete these items?");

    const itemsDeletedList = () => {
      axios({
        method: 'delete',
        url: "" + API.baseURL + "Enitities/MultipleDeleteEntities",
        data: selectedRow

      })
        .then(function (response) {
          setAlertOpen(true);
          setAlertMessage("Deleted Successfully");
          setConfirmOpen(false);
          loadAllEntities();
        }).catch(function (error) {
          setAlertOpen(true);
          setAlertMessage(error.request.response);
          setConfirmOpen(false);
        });
    }
    setRemoveItemSuccess(() => itemsDeletedList);

  };



  const onDeleteTableRowHandler = (rowID) => {
    console.log(rowID);
    handleRowMenuClose();
    setConfirmOpen(true);
    setConfirmMessage("Do you really want to delete this item?");

    const itemsDeletedList = () => {
      axios
        .delete(`${API.baseURL}entities/${rowID}`)
        .then(function (response) {
          let List = entitiesList.filter(row => row.Id !== rowID)
          setEntitiesList(List);
          setAlertOpen(true);
          setAlertMessage("Deleted Successfully");
          setConfirmOpen(false);
        }).catch(function (error) {
          setAlertOpen(true);
          setAlertMessage(error.request.response);
          setConfirmOpen(false);
        });
    }
    setRemoveItemSuccess(() => itemsDeletedList);
  }

  const onEditTableRowHandler = (rowData) => {
    handleRowMenuClose();
    setShowCreateForm(false);
    setUpdateForm(rowData);
  }

  const onEditDoneHandler = (rowData) => {
    setUpdateForm(null);
  }


  const onChangeSearchHandler = (event) => {
    setSearchInputValue(event.target.value);
    console.log(event.target.value);
    if (event.target.value) {
      let filteredList = entitiesList.filter(item => item.Name.toLowerCase().includes(event.target.value.toLowerCase()));
      console.log(filteredList);
      setEntitiesList(filteredList);
    } else {
      loadAllEntities();
    }
  }

  const handleTableRowClick = (event, ID) => {
    console.log(ID);
    const selectedIndex = selectedRow.indexOf(ID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRow, ID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRow.slice(1));
    } else if (selectedIndex === selectedRow.length - 1) {
      newSelected = newSelected.concat(selectedRow.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRow.slice(0, selectedIndex),
        selectedRow.slice(selectedIndex + 1),
      );
    }
    if (newSelected.length > 0) {
      setMultipleDelete(true)
    } else {
      setMultipleDelete(false)
    }
    setSelectedRow(newSelected);
  };

  const handelAlertView = () => {
    setAlertOpen(!alertOpen);
  }

  const handelAlertClose = () => {
    setAlertOpen(!alertOpen);
  }

  const handelDisagreeConfirm = () => {
    setConfirmOpen(!confirmOpen);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const _onSelectAll = () => {
    let ids = [];
    if (selectedRow.length !== entitiesList.length) {
      ids = entitiesList.map(item => item.Id);
    }
    setSelectedRow(ids);
  }

  const isSelected = (name) => selectedRow.indexOf(name) !== -1;

  return (
    <div className={classes.baseRootPageStyle}>
      <Loader showLoader={showLoader} />

      <SectionHeader onAddClick={onMenuAddHandler} list={entitiesList} setList={setEntitiesList} loadAll={loadAllEntities} />
      
      {/* <div className={classes.tableHeaderContainer}> */}

        {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <Search />
            </div>
            <InputBase
              value={searchInputValue}
              placeholder="Search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{'aria-label': 'search'}}
              onChange={onChangeSearchHandler}
            />
          </div> */}
          {/* <IconButton onClick={handleMenuClick} style={{ marginLeft: '10px', color: Colors.black }} aria-controls="simple-menu" aria-haspopup="true" component="span">
            <MoreVert />
          </IconButton> */}

          {/* <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={onMenuAddHandler}>
              <img className={classes.menuIconStyle} src={require("../../assets/img/Add.png")} />

              <div style={{ marginLeft: '8px' }}>
                Add
              </div>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <img className={classes.menuIconStyle} src={require("../../assets/img/Export.png")} />

              <div style={{ marginLeft: '8px' }}>
                Export
              </div>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <img className={classes.menuIconStyle} src={require("../../assets/img/Import.png")} />

              <div style={{ marginLeft: '8px' }}>
                Import
              </div>
            </MenuItem>
            {
              selectedRow.length > 0 ?
                <MenuItem onClick={onMenuMultipleDelete}>
                  <img className={classes.menuIconStyle} src={require("../../assets/img/Delete-orange.png")} />
                  <div style={{ marginLeft: '8px' }}>
                    Delete
                  </div>
                </MenuItem>
                : ""
            }

          </Menu> */}

        {/* </div> */}
      {/* </div> */}

      {
        showCreateForm ?
          <CreateEntities onHideCreatFormHandler={onHideCreatFormHandler} loadAllEntities={loadAllEntities} />
          : ""
      }

      {
        updateForm ?
          <UpdateEntities onEditDoneHandler={onEditDoneHandler} updateForm={updateForm} onHideCreatFormHandler={onHideCreatFormHandler} loadAllEntities={loadAllEntities} />
          : ""
      }

      <AlertDialog handelAlertView={handelAlertView} handelAlertClose={handelAlertClose} alertOpen={alertOpen} alertMessage={alertMessage} />


      <ConfirmDialog handelDisagreeConfirm={handelDisagreeConfirm} handelAgreeConfirm={removeSuccess} confirmOpen={confirmOpen} confirmMessage={confirmMessage} />
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            stickyHeader aria-label="sticky table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onSelectAllClick={_onSelectAll}
            />

            {entitiesList.length > 0 ? (
              <TableBody>
                {stableSort(entitiesList, getComparator(order, orderBy))
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.Id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.Id}
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                      >

                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) => handleTableRowClick(event, row.Id)}
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>

                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="default"
                        >
                          {row.Id}
                        </TableCell>
                        <TableCell>{row.Name}</TableCell>
                        <TableCell>  {row.Code}</TableCell>
                        <TableCell>{row.Country}</TableCell>
                        <TableCell> {row.City} </TableCell>
                        <TableCell>
                          <div>
                            <img onClick={() => onEditTableRowHandler(row)} className={clsx("mx-2", classes.actions)} src={Edit} />
                            <img onClick={() => onDeleteTableRowHandler(row.Id)} className={clsx("mx-2", classes.actions)} src={Delete} />
                          </div>

                          {/* <IconButton onClick={(event) => handleRowMenuClick(event, row)} style={{ marginLeft: '10px', color: Colors.black }} aria-controls="simple-menu" aria-haspopup="true" component="span" disabled={multipleDelete}>
                            <MoreHoriz style={{ color: Colors.black }} />
                          </IconButton>

                          <Menu
                            id="simple-menu"
                            anchorEl={moreRowEl}
                            keepMounted
                            open={Boolean(moreRowEl)}
                            onClose={handleRowMenuClose}
                          >
                            <MenuItem onClick={() => onEditTableRowHandler(selectedRowData)} style={{ justifyContent: 'center' }}>
                              <img style={{ width: '20% !important' }} className={classes.menuIconStyle} src={require("../../assets/img/edit_3x2.png")} />
                            </MenuItem>
                            <MenuItem onClick={() => onDeleteTableRowHandler(selectedRowData.Id)} style={{ justifyContent: 'center' }}>
                              <img className={classes.menuIconStyle} style={{ width: '20% !important' }} src={require("../../assets/img/Delete-orange.png")} />
                            </MenuItem>
                          </Menu> */}

                        </TableCell>

                      </TableRow>
                    );
                  })}

              </TableBody>

            ) : (
              <TableBody>
                <TableRow >
                  <TableCell colSpan={7} style={{ textAlign: 'center' }} > There Are No Entities Yet, Try To Add Some</TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>

    </div>
  );
}


export default Entities;