import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CreateScaleDetails from "../../components/CreateScaleCardDetails/CreateScaleDetails";
import API from "../../varaibles/baseApi";
import {
  Paper,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
} from "@material-ui/core";
import AlertDialog from "../../components/AlertDialog";
import ConfirmDialog from "../../components/ConfirmDialog";
import Loader from "../../components/Loader";
import Colors from "../../varaibles/colors";
import InputBase from "@material-ui/core/InputBase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { MoreHoriz, Search, MoreVert } from "@material-ui/icons";
import { createStyle } from "./styles";

import { SectionHeader } from '../../components/SectionHeader';

import Delete from '../../assets/icons/delete_red.svg';
import Edit from '../../assets/icons/edit.svg';
import clsx from 'clsx'


const headCells = [
  {
    id: "Name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "Minimum Value",
    numeric: true,
    disablePadding: false,
    label: "Minimum Value",
  },
  {
    id: "Maximum Value",
    numeric: true,
    disablePadding: false,
    label: "Maximum Value",
  },
  {
    id: "Color",
    numeric: true,
    disablePadding: false,
    label: "Color",
  },
  { id: "Action", numeric: true, disablePadding: false, label: "Action" },
];


function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeaderStyle}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="default"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: "bold" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles(createStyle);

const Scale = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [selectedRow, setSelectedRow] = useState([]);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [scoreCardDetailsList, setScoreCardDetailsList] = useState([]);

  const [newProduct, setNewProduct] = useState();

  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeSuccess, setRemoveItemSuccess] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const [moreRowEl, setMoreRowEl] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [updateForm, setUpdateForm] = useState(null);
  const [editMoodForm, setEditMoodForm] = useState(false);
  const [multipleDelete, setMultipleDelete] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  const loadAllScoreCardDetails = () => {
    axios
      .get("" + API.baseURL + "scoreScale")
      .then((response) => {
        setScoreCardDetailsList(response.data);
      });
  };

  useEffect(() => {
    loadAllScoreCardDetails();
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onMenuAddHandler = () => {
    handleMenuClose();
    setShowCreateForm(!showCreateForm);
    setUpdateForm(null);
  };

  const onHideCreatFormHandler = () => {
    setShowCreateForm(false);
  };

  const handleRowMenuClick = (event, rowData) => {
    setMoreRowEl(event.currentTarget);
    setSelectedRowData(rowData);
  };

  const handleRowMenuClose = () => {
    setMoreRowEl(null);
  };

  const onMenuMultipleDelete = () => {
    handleMenuClose();
    setConfirmOpen(true);
    setConfirmMessage("Do you really want to delete these items?");

    const itemsDeletedList = () => {
      axios({
        method: "delete",
        url: "" + API.baseURL + "ScoreScales/MultipleDeleteScoreScale",
        data: selectedRow,
      })
        .then(function (response) {
          setAlertOpen(true);
          setAlertMessage("Deleted Successfully");
          setConfirmOpen(false);
          loadAllScoreCardDetails();
        })
        .catch(function (error) {
          setAlertOpen(true);
          setAlertMessage(error.request.response);
          setConfirmOpen(false);
        });
    };
    setRemoveItemSuccess(() => itemsDeletedList);
  };

  const onDeleteTableRowHandler = (rowID) => {
    handleRowMenuClose();
    setConfirmOpen(true);
    setConfirmMessage("Do you really want to delete this item?");

    const itemsDeletedList = () => {
      axios
        .delete(`${API.baseURL}ScoreScales/${rowID}`)
        .then(function (response) {
          let List = scoreCardDetailsList.filter((row) => row.id !== rowID);
          setScoreCardDetailsList(List);
          setAlertOpen(true);
          setAlertMessage("Deleted Successfully");
          setConfirmOpen(false);
        })
        .catch(function (error) {
          setAlertOpen(true);
          setAlertMessage(error.request.response);
          setConfirmOpen(false);
        });
    };
    setRemoveItemSuccess(() => itemsDeletedList);
  };

  const onEditTableRowHandler = (rowData) => {
    handleRowMenuClose();
    setShowCreateForm(false);
    setUpdateForm(rowData);
  };

  const onEditDoneHandler = (rowData) => {
    setUpdateForm(null);
  };

  const onChangeSearchHandler = (event) => {
    setSearchInputValue(event.target.value);

    if (event.target.value) {
      let filteredList = scoreCardDetailsList.filter((item) =>
        item.scaleName.toLowerCase().includes(event.target.value.toLowerCase())
      );

      setScoreCardDetailsList(filteredList);
    } else {
      loadAllScoreCardDetails();
    }
  };

  const handleTableRowClick = (event, ID) => {
    const selectedIndex = selectedRow.indexOf(ID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRow, ID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRow.slice(1));
    } else if (selectedIndex === selectedRow.length - 1) {
      newSelected = newSelected.concat(selectedRow.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRow.slice(0, selectedIndex),
        selectedRow.slice(selectedIndex + 1)
      );
    }

    if (newSelected.length > 0) {
      setMultipleDelete(true);
    } else {
      setMultipleDelete(false);
    }
    setSelectedRow(newSelected);
  };

  const handelAlertView = () => {
    setAlertOpen(!alertOpen);
  };

  const handelAlertClose = () => {
    setAlertOpen(!alertOpen);
  };

  const handelDisagreeConfirm = () => {
    setConfirmOpen(!confirmOpen);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (name) => selectedRow.indexOf(name) !== -1;

  const _onSelectAll = () => {
    let ids = [];
    if (selectedRow.length !== scoreCardDetailsList.length) {
      ids = scoreCardDetailsList.map(item => item.id);
    }
    setSelectedRow(ids);
  }

  return (
    <div className={classes.baseRootPageStyle}>
      <Loader showLoader={showLoader} />

      {/* <div className={classes.tableHeaderContainer}>
        <div className={classes.sectionTitle}>Score Scale</div>
        <div style={{display: "flex", alignItems: "center"}}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <Search />
            </div>
            <InputBase
              value={searchInputValue}
              placeholder="Search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{"aria-label": "search"}}
              onChange={onChangeSearchHandler}
            />
          </div>
          <IconButton
            onClick={handleMenuClick}
            style={{marginLeft: "10px", color: Colors.black}}
            aria-controls="simple-menu"
            aria-haspopup="true"
            component="span"
          >
            <MoreVert />
          </IconButton>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={onMenuAddHandler}>
              <img
                className={classes.menuIconStyle}
                src={require("../../assets/img/Add.png")}
              />

              <div style={{marginLeft: "8px"}}>Add</div>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <img
                className={classes.menuIconStyle}
                src={require("../../assets/img/Export.png")}
              />

              <div style={{marginLeft: "8px"}}>Export</div>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <img
                className={classes.menuIconStyle}
                src={require("../../assets/img/Import.png")}
              />

              <div style={{marginLeft: "8px"}}>Import</div>
            </MenuItem>
            {selectedRow.length > 0 ? (
              <MenuItem onClick={onMenuMultipleDelete}>
                <img
                  className={classes.menuIconStyle}
                  src={require("../../assets/img/Delete-orange.png")}
                />
                <div style={{marginLeft: "8px"}}>Delete</div>
              </MenuItem>
            ) : (
                ""
              )}
          </Menu>
        </div>
      </div> */}

      <SectionHeader onAddClick={onMenuAddHandler} list={scoreCardDetailsList} setList={setScoreCardDetailsList} loadAll={loadAllScoreCardDetails} hideIcons={{
        delete: true
      }} />


      {showCreateForm || updateForm ? (
        <CreateScaleDetails
          onEditDoneHandler={onEditDoneHandler}
          prevData={updateForm}
          onHideCreatFormHandler={onHideCreatFormHandler}
          loadAllScoreCardDetails={loadAllScoreCardDetails}
        />
      ) : (
        ""
      )}

      <AlertDialog
        handelAlertView={handelAlertView}
        handelAlertClose={handelAlertClose}
        alertOpen={alertOpen}
        alertMessage={alertMessage}
      />

      <ConfirmDialog
        handelDisagreeConfirm={handelDisagreeConfirm}
        handelAgreeConfirm={removeSuccess}
        confirmOpen={confirmOpen}
        confirmMessage={confirmMessage}
      />
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onSelectAllClick={_onSelectAll}
            />

            {scoreCardDetailsList.length > 0 ? (
              <TableBody>
                {stableSort(
                  scoreCardDetailsList,
                  getComparator(order, orderBy)
                ).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  const { id, order,
                    minValue,
                    maxValue,
                    scaleName,
                    scaleColor } = row

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.Id}
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) =>
                            handleTableRowClick(event, id)
                          }
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="default"
                      >
                        {scaleName}
                      </TableCell>
                      <TableCell>{minValue}</TableCell>
                      <TableCell>{maxValue}</TableCell>
                      {/* {row.parameter ? (
                        <TableCell>{row.parameter.parameterName}</TableCell>
                      ) : (
                          <TableCell>{row.childCard.cardName} Card</TableCell>
                        )}{" "} */}
                      <TableCell>{scaleColor}</TableCell>
                      {/* <TableCell> {row.status.label} </TableCell> */}
                      <TableCell>
                        {/* <IconButton
                          onClick={(event) => handleRowMenuClick(event, row)}
                          style={{ marginLeft: "10px", color: Colors.black }}
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          component="span"
                          disabled={multipleDelete}
                        >
                          <MoreHoriz style={{ color: Colors.black }} />
                        </IconButton> */}

                        {/* <Menu
                          id="simple-menu"
                          anchorEl={moreRowEl}
                          keepMounted
                          open={Boolean(moreRowEl)}
                          onClose={handleRowMenuClose}
                        >
                          <MenuItem
                            onClick={() =>
                              onEditTableRowHandler(selectedRowData)
                            }
                            style={{ justifyContent: "center" }}
                          >
                            <img
                              style={{ width: "20% !important" }}
                              className={classes.menuIconStyle}
                              src={require("../../assets/img/edit_3x2.png")}
                            />
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              onDeleteTableRowHandler(selectedRowData.id)
                            }
                            style={{ justifyContent: "center" }}
                          >
                            <img
                              className={classes.menuIconStyle}
                              style={{ width: "20% !important" }}
                              src={require("../../assets/img/Delete-orange.png")}
                            />
                          </MenuItem>
                        </Menu> */}
                        <div>
                          <img onClick={() => onEditTableRowHandler(row)} className={clsx("mx-2", classes.actions)} src={Edit} />
                          <img onClick={() => onDeleteTableRowHandler(row.Id)} className={clsx("mx-2", classes.actions)} src={Delete} />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    There Are No Entities Yet, Try To Add Some
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default Scale;
