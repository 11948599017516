// import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import {
//   CssBaseline,
//   Drawer,
//   Hidden,
//   ListItemIcon,
//   ListItemText,
//   ListItem,
//   List,
//   colors,
//   Button
// } from '@material-ui/core';

// import Colors from '../../varaibles/colors'

// import Dashboard from '@material-ui/icons/Dashboard';
// import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
// import Settings from '@material-ui/icons/Settings';
// import BarChart from '@material-ui/icons/BarChart';
// import FileCopy from '@material-ui/icons/FileCopy';
// import LocalShipping from '@material-ui/icons/LocalShipping';
// import Person from '@material-ui/icons/Person';
// import LocalAtm from '@material-ui/icons/LocalAtm';
// import Apartment from '@material-ui/icons/Apartment';
// import Business from '@material-ui/icons/Business';
// import Label from '@material-ui/icons/Label';
// import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
// import { NavLink } from 'react-router-dom';
// import Variables from '../../varaibles/varialbles';
// import NavMenu from '../header/NavMenu'
// import './Drawer.css'
// import { useHistory } from "react-router-dom";


// import clsx from 'clsx';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';


// import { useDispatch, useSelector } from "react-redux";
// import { logout } from "../../store/actions/auth";

// const drawerWidth = Variables.drawerWidth;

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//   },
//   // drawer: {
//   //   [theme.breakpoints.up('sm')]: {
//   //     width: drawerWidth,
//   //     flexShrink: 0,
//   //   },
//   // },

//   // new
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//     whiteSpace: 'nowrap',
//     '& a.active': {
//       color: Colors.brown
//     },
//     '& a.active .MuiListItemIcon-root': {
//       color: Colors.brown
//     },
//     '& a.active span': {
//       fontWeight: '800',
//     }
//   },

//   drawerOpen: {
//     width: drawerWidth,
//     transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },

//   drawerClose: {
//     transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     overflowX: 'hidden',
//     width: theme.spacing(7) + 1,
//     [theme.breakpoints.up('sm')]: {
//       width: theme.spacing(9) + 1,
//     },
//   },


//   // appBar: {
//   //   [theme.breakpoints.up('sm')]: {
//   //     width: `calc(100% - ${drawerWidth}px)`,
//   //     marginLeft: drawerWidth,
//   //   },
//   // },


//   // new
//   appBar: {
//     zIndex: theme.zIndex.drawer + 1,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//   },

//   // new
//   appBarShift: {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   // menuButton: {
//   //   marginRight: theme.spacing(2),
//   //   [theme.breakpoints.up('sm')]: {
//   //     display: 'none',
//   //   },
//   // },


//   // new
//   menuButton: {
//     marginRight: 36,
//   },


//   // new
//   hide: {
//     display: 'none',
//   },


//   // necessary for content to be below app bar

//   // toolbar: theme.mixins.toolbar,


//   // new
//   toolbar: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-end',
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//   },


//   drawerPaper: {
//     width: drawerWidth,
//     '& a.active': {
//       color: '#007fdf !important'
//     },
//     '& a.active .MuiListItemIcon-root': {
//       color: '#007fdf !important'
//     },
//     '& a.active span': {
//       fontWeight: '800',
//     }
//   },
//   // content: {
//   //   flexGrow: 1,
//   //   padding: theme.spacing(3),
//   //   overflowX: 'auto',
//   //   background: '#e9e9e9',
//   //   height: '100vh'
//   // },

//   // new
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3),
//   },

//   item: {
//     display: 'flex',
//     paddingTop: 0,
//     paddingBottom: 0
//   },
//   icon: {
//     color: theme.palette.icon,
//     width: 24,
//     height: 24,
//     display: 'flex',
//     alignItems: 'center',
//     marginRight: theme.spacing(1)
//   },
//   button: {
//     color: colors.blueGrey[800],
//     padding: '10px 8px',
//     justifyContent: 'flex-start',
//     textTransform: 'none',
//     letterSpacing: 0,
//     width: '100%',
//     fontWeight: theme.typography.fontWeightMedium
//   },
//   drawerItem: {
//     color: '#969696',
//     '& span': {
//       fontSize: '11px !important'
//     },
//     '& svg': {
//       fontSize: '20px !important'
//     }
//   },
//   headerLogo: {
//     height: '40px',
//   },
//   grow: {
//     flexGrow: 1,
//   },
//   sectionDesktop: {
//     display: "none",
//     [theme.breakpoints.up("md")]: {
//       display: "flex",
//     },
//   },
//   contentShift: {
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginLeft: 0,
//   },
//   // drawerItemIcon: {
//   //   minWidth: 'auto',
//   //    marginRight: '14px'
//   // }
// }));

// function ResponsiveDrawer(props) {
//   const { container } = props;
//   const classes = useStyles();
//   const history = useHistory();
//   const theme = useTheme();
//   const [mobileOpen, setMobileOpen] = useState(false);

//   const menuId = "primary-search-account-menu";

//   const dispatch = useDispatch();

//   const { isLoggedIn } = useSelector(state => state.auth);


//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };


//   const [open, setOpen] = React.useState(false);

//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

//   const logOut = () => {
//     dispatch(logout());
//     props.history.replace("/signIn");
//     // history.push("/signIn");
// }

//   const drawerItems = [
//     { title: "Entities", icon: <PlaylistAddCheck />, path: "/entities" },
//     { title: "Parameters", icon: <Apartment />, path: "/parameters" },
//     { title: "Parameters Maps", icon: <Business />, path: "/parametersMaps" },
//     { title: "Factors", icon: <Label />, path: "/factors" },
//     { title: "Integrations", icon: <FileCopy />, path: "/integrations" },
//     { title: "Score Cards", icon: <LocalShipping />, path: "/scoreCards" },
//     { title: "Card Details", icon: <Person />, path: "/cardDetails" },
//     { title: "Managed List", icon: <LocalAtm />, path: "/managedList" },
//     // { title: "Nodes", icon: <BarChart />, path: "/nodes" },
//   ]


//   const drawer = (
//     <div>
//       <div className={classes.toolbar} />
//       <List>
//         {drawerItems.map((item, index) => (
//           <ListItem className={classes.drawerItem} component={NavLink} exact={item.exact} to={item.path} button key={item.title}>
//             <ListItemIcon className={classes.drawerItemIcon}>{item.icon}</ListItemIcon>
//             <ListItemText primary={item.title} />
//           </ListItem>
//         ))}

//       </List>

//     </div>
//   );

//   return (
//     <div className={classes.root}>
//       <CssBaseline />
//       <AppBar
//         position="fixed"
//         className={clsx(classes.appBar, {
//           [classes.appBarShift]: open,
//         })}
//         style={{ background: 'white' }}
//       >
// <Toolbar>
//   {isLoggedIn ? <IconButton
//     aria-label="open drawer"
//     onClick={handleDrawerOpen}
//     edge="start"
//     className={clsx(classes.menuButton, {
//       [classes.hide]: open,
//     })}
//     style={{ background: 'white' }}
//   >
//     <MenuIcon />
//   </IconButton> : ""}

//   <img className={classes.headerLogo} src={require("../../assets/img/AgileScore_Logo.png")} />

//   <div className={classes.grow} />
//   <div className={classes.sectionDesktop}>
//     {
//       isLoggedIn ?
//           <Button style={{ color: Colors.yellow }} onClick={logOut}>
//             Logout
//           </Button>
//         :
//         <ListItem component={NavLink} to={"/signIn"} button >
//           <Button variant="contained" style={{ background: Colors.yellow, color: 'white' }}>
//             Sign In
//           </Button>
//         </ListItem>
//     }

//   </div>
// </Toolbar>
//       </AppBar>
//       {isLoggedIn ? <nav className={classes.drawer} aria-label="mailbox folders">
//         <Drawer
//           variant="permanent"
//           className={clsx(classes.drawer, {
//             [classes.drawerOpen]: open,
//             [classes.drawerClose]: !open,
//           })}
//           classes={{
//             paper: clsx({
//               [classes.drawerOpen]: open,
//               [classes.drawerClose]: !open,
//             }),
//           }}
//         >
//           <div className={classes.toolbar}>
//             <IconButton onClick={handleDrawerClose}>
//               {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
//             </IconButton>
//           </div>
//           <Divider />
//           {drawer}
//         </Drawer>

//       </nav>
//         : ""}
//       <main
//         className={clsx(classes.content, {
//           [classes.contentShift]: open,
//         })}
//       >
//         <div className={classes.toolbar} />
//         {props.children}
//       </main>
//     </div>
//   );
// }

// ResponsiveDrawer.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   container: PropTypes.any,
// };

// export default ResponsiveDrawer;


////////////=============================//////////////////======////////////


import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import {
  CssBaseline,
  Drawer,
  Hidden,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  colors,
  Button
} from '@material-ui/core';

// last new one 

import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import Settings from '@material-ui/icons/Settings';
import BarChart from '@material-ui/icons/BarChart';
import FileCopy from '@material-ui/icons/FileCopy';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Person from '@material-ui/icons/Person';
import LocalAtm from '@material-ui/icons/LocalAtm';
import Apartment from '@material-ui/icons/Apartment';
import Business from '@material-ui/icons/Business';
import Label from '@material-ui/icons/Label';
import { NavLink, useHistory } from 'react-router-dom';
import NavMenu from '../header/NavMenu'
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/auth";
import Colors from '../../varaibles/colors'
import { withRouter, useLocation } from "react-router-dom";
import { Sidebar } from '../Sidebar';
import DeloitteLogo from '../../assets/img/deloitteBlack.png'


import EntitiesIcon from '../../assets/icons/entities.svg';
import ParametersIcon from '../../assets/icons/parameters.svg';
import FactorsIcon from '../../assets/icons/factors.svg';
import ScoreCardIcon from '../../assets/icons/scoreCard.svg';
import IntegrationsIcon from '../../assets/icons/integration.svg';
import MappingIcon from '../../assets/icons/mapping.svg';
import ManagedListIcon from '../../assets/icons/managedList.svg';
import ScaleIcon from '../../assets/icons/scale.svg';
import ChangeLanguageIcon from '../../assets/icons/ChangeLanguage.svg'
import LogoutIcon from '../../assets/icons/logout.svg'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // marginTop: '50px'
    minHeight: '100vh'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: '25%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '& a.active': {
      color: Colors.black
    },
    '& a.active .MuiListItemIcon-root': {
      color: Colors.black
    },
    '& a.active span': {
      fontWeight: '800',
    }
  },
  drawerPaper: {
    width: drawerWidth,
    '& a.active': {
      color: '#007fdf !important'
    },
    '& a.active .MuiListItemIcon-root': {
      color: '#007fdf !important'
    },
    '& a.active span': {
      fontWeight: '800',
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // padding: 0,
    backgroundColor: '#F6F6F6'
  },

  //   // last new one

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 1),
    marginTop: '20px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },

  drawerItem: {
    height: '40px',
    paddingLeft: '0 !important',
    color: '#969696',
    '& span': {
      fontSize: '11px !important'
    },
    '& svg': {
      fontSize: '20px !important'
    }
  },
  headerLogo: {
    height: '40px',
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  sidebar: {
    width: '25%',
    background: '#fff',
    display: 'grid'

  },
  drawerContainer: {
    paddingTop: '35px'
  },
  drawerTitle: {
    color: Colors.deloitte_green,
    cursor: 'pointer'
  },
  listItemText: {
    marginLeft: '20px',
    color: 'black',
    '& .MuiTypography-root': {
      fontSize: '14px !important'
    }
  },
  sideBarList: {
    padding: '10px !important'
  },
  chosenItem: {
    backgroundColor: '#1C1C1C0D',
    borderRadius: '8px'
  },
  chosenIcon: {
    filter: 'invert(57%) sepia(79%) saturate(430%) hue-rotate(40deg) brightness(101%) contrast(86%)'
  },
  chosenText: {
    color: Colors.deloitte_green
  },
  chosenDiv: {
    backgroundColor: '#86BC24CC',
    padding: '10px 2px 10px 2px',
    borderRadius: '8px',
    marginRight: '7px'
  },
  actionButtons: {
    alignSelf: 'end',
    marginBottom: '20px'
  },
  changeLanguageButton: {
    border: 'solid 1px',
    borderColor: Colors.deloitte_green,
    padding: '10px',
    borderRadius: '10px',
    color: Colors.deloitte_green,
    minWidth: '140px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: Colors.deloitte_green,
      color: '#fff',
      '& img': {
        filter: 'brightness(10)',
      }
    },
    '&:focus': {
      outline: 'none'
    }
  },
  logoutButton: {
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: Colors.deloitte_green,
    color: '#fff',
    minWidth: '140px',
    '&:hover': {
      backgroundColor: Colors.accessible_green
    },
    textTransform: 'none',
    '&:focus': {
      outline: 'none'
    }
  },
  breadcrumb: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '13%',
    '& > p': {
      marginBottom: 0,
      fontSize: '14px'
    }
  },
  section: {
    color: 'rgba(28, 28, 28, 0.40)'
  },
  slash: {
    color: 'rgba(28, 28, 28, 0.40)'
  },
  logo: {
    cursor: 'pointer'
  }
  // drawerItemIcon: {
  //   minWidth: 'auto',
  //    marginRight: '14px'
  // }

}));

const ResponsiveDrawer = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory()
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(state => state.auth);
  const location = useLocation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const logOut = () => {
    props.history.replace("/");
    handleDrawerClose();
    dispatch(logout());
    // history.push("/signIn");
  }

  const drawerItems = [
    { title: "Entities", icon: EntitiesIcon, path: "/entities" },
    { title: "Parameters", icon: ParametersIcon, path: "/parameters" },
    { title: "Factors", icon: FactorsIcon, path: "/factors" },
    { title: "Risk Profile", icon: ScoreCardIcon, path: "/scoreCards" },
    { title: "Integrations", icon: IntegrationsIcon, path: "/integrations" },
    { title: "Card Details", icon: ScoreCardIcon, path: "/cardDetails" },
    { title: "Parameters Maps", icon: MappingIcon, path: "/parametersMaps" },
    { title: "Managed List", icon: ManagedListIcon, path: "/managedList" },
    { title: "Scale", icon: ScaleIcon, path: "/scale" },
  ]

  const capitalizeAfterSlash = (inputString) => {
    if (inputString.startsWith("/")) {
      inputString = inputString.substring(1);
    }

    if (inputString.length === 0) {
      return "";
    }

    const firstCharacter = inputString[0];
    const restOfString = inputString.substring(1);

    return firstCharacter.toUpperCase() + restOfString;
  }

  console.log({ location });



  const drawer = (
    <div className={classes.drawerContainer}>
      <img className={classes.logo} src={DeloitteLogo} onClick={() => history.push('/')} />
      <div className={classes.toolbar}>
        <p className={clsx('mx-4', classes.drawerTitle)}>Settings</p>
        {/* <p className='mx-4'>Security</p> */}
      </div>
      <List className={classes.sideBarList}>
        {drawerItems.map((item, index) => {
          const chosen = location.pathname === item.path
          return (
            <ListItem className={clsx(classes.drawerItem, { [classes.chosenItem]: chosen })} component={NavLink} exact={item.exact} to={item.path} button key={item.title}>
              {/* <ListItemIcon className={classes.drawerItemIcon}>{item.icon}</ListItemIcon> */}
              {chosen && <div className={classes.chosenDiv}></div>}
              <img src={item.icon} className={clsx({ [classes.chosenIcon]: chosen })} />
              <ListItemText className={clsx(classes.listItemText, { [classes.chosenText]: chosen })} primary={item.title} />
            </ListItem>
          )
        })}

      </List>
    </div>
  );


  return (
    <div className={classes.root}>
      <CssBaseline />
      {(!isLoggedIn || location.pathname === '/') && <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{ background: 'black' }}
      >
        <Toolbar>
          {/* {isLoggedIn ? <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
            style={{ background: 'white' }}
          >
            <MenuIcon />
          </IconButton> : ""} */}

          <img className={classes.headerLogo} src={require("../../assets/img/deloitte_logo.png")} />
          {/* <div className={classes.grow} /> */}

          <div className={classes.grow} />
          {isLoggedIn&&<div>
            <Button className='mx-5' onClick={()=>history.push('/entities')} variant="contained" style={{ background: Colors.deloitte_green, color: 'white' }}>
              Settings
            </Button>
          </div>}

          <div className={classes.sectionDesktop}>
            {
              isLoggedIn ?
                <Button variant="contained" style={{ background: Colors.deloitte_green, color: 'white' }} onClick={logOut}>
                  Logout
                </Button>
                :
                <ListItem component={NavLink} to={"/signIn"} button >
                  <Button variant="contained" style={{ background: Colors.deloitte_green, color: 'white' }}>
                    Sign In
                  </Button>
                </ListItem>
            }

          </div>
        </Toolbar>
      </AppBar>}
      {(isLoggedIn && location.pathname !== '/') ?
        <Sidebar
          className={classes.sidebar}
        >
          {/* <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div> */}
          {/* <Divider /> */}

          {drawer}
          <div className={classes.actionButtons}>
            <Button disableElevation={true} className={clsx(classes.changeLanguageButton, 'mx-2')}>
              <img src={ChangeLanguageIcon} />
              <span className='mx-2'>Language</span>
            </Button>
            <Button disableElevation={true} onClick={logOut} className={clsx(classes.logoutButton, 'mx-2')}>
              <img src={LogoutIcon} />
              <span className='mx-2'>Log Out</span>
            </Button>
          </div>
        </Sidebar>
        : ""}

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={clsx(classes.drawerHeader, 'px-5 mt-1')}>
          <div className={classes.breadcrumb}>
            {console.log({cap: capitalizeAfterSlash(location.pathname), pathName: location.pathname})}
            <p className={clsx(classes.section, 'mx-2')}>Settings</p> <p className={clsx(classes.slash, 'mx-2')}>/</p> <p className='mx-2'>{capitalizeAfterSlash(location.pathname)}</p>
          </div>
        </div>
        <hr />
        <div className='p-4'>
          {props.children}
        </div>
      </main>
    </div>
  );
}

export default withRouter(ResponsiveDrawer);