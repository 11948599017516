import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Paper, Button, IconButton, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { BackupOutlined, TramOutlined } from "@material-ui/icons";
import TextInput from "../input/input";
import axios from 'axios';
import API from '../../varaibles/baseApi';
import AlertDialog from '../AlertDialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';

import Colors from '../../varaibles/colors'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: "2%",
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        boxShadow: 'none',
    },
    gridContainer: {
        // justifyContent: "flex-end",
        width: '100%',
        borderRadius: '15px',
        padding: '15px',
        background: '#fff'
    },
    select: {
        border: "none",
        outline: "none",
        width: "100%",
    },
    button: {
        lineHeight: 1.43,
        width: "100%",
        padding: "7px",
        color: "#005074",
        borderColor: "#005074",
        textTransform: "none",
        fontWeight: 'bold'
    },
    buttonFilled: {
        lineHeight: 1.43,
        width: "100%",
        padding: "7px",
        color: "white",
        background: "#005074",
        // borderColor: "#005074",
        textTransform: "none",
    },
    errorMessage: {
        textAlign: "left",
        marginLeft: "4px",
        color: "red",
        fontSize: "12px",
    },
    textField: {
        width: '100%',
        underline: {
            "&&&:before": {
                borderBottom: "none",
            },
            "&&:after": {
                borderBottom: "none"
            }
        }
    },
    inputLabel: {
        marginBottom: '2%',
        // fontWeight: 700,
        textAlign: 'left'
    },
    outlinedInputStyle: { background: "white", width: "100%", height: '41px', borderRadius: '10px' },
    selectInputPaper: {
        border: "1px solid rgba(0, 0, 0, 0.23) !important",
        height: "41px",
        width: "99%",
        borderRadius: '10px'
    },
    createEntitiesActionButtons: { textTransform: 'none', width: '10%', height: '41px', borderRadius: '10px' },
    addButton: {
        backgroundColor: Colors.deloitte_green,
        color: Colors.deloitte_green,
    },
    cancelButton: {
        backgroundColor: '#fff',
        color: Colors.deloitte_green,
        borderColor: Colors.deloitte_green
    }
}));




function UpdateScoreCards(props) {

    /***************     INPUTS ERROR HANDLER      **************** */
    const [showLoader, setShowLoader] = useState(false);

    const [levelError, setLevelError] = useState(false);
    const [cardNameError, setCardNameError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [versionError, setVersionError] = useState(false);
    const [categoryError, setCategoryError] = useState(false);

    const [levelList, setLevelList] = useState(null);
    const [categoryList, setCategoryList] = useState(null);

    /***************     INPUTS VALUES HANDLER      **************** */
    const [levelInputVal, setLevelInputVal] = useState("Level Options");
    const [cardNameInputVal, setCardNameInputVal] = useState('');
    const [descriptionInputVal, setDescriptionInputVal] = useState('');
    const [versionInputVal, setVersionInputVal] = useState('');
    const [isParentInputVal, setIsParentInputVal] = useState(false);
    const [categoryIdInputVal, setCategoryIdInputVal] = useState("Category Options");

    /***************     SELECTS INPUT OPTIONS HANDLER      **************** */

    const [alertMessage, setAlertMessage] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);

    useEffect(() => {
        const updateFormData = props.updateForm;

        axios
            .get("" + API.baseURL + "Level/LockupLevels")
            .then((response) => {
                let levelList = response.data.map((level) => (
                    <option value={level.id} data-id={level.label} key={level.id}>{level.label}</option>
                ));
                setLevelList(levelList);
            });

        axios
            .get("" + API.baseURL + "categories")
            .then((response) => {
                let categoryList = response.data.map((category) => (
                    <option value={category.id} data-id={category.label} key={category.id}>{category.label}</option>
                ));
                setCategoryList(categoryList);
            });


        setLevelInputVal(updateFormData.level.id);
        setCardNameInputVal(updateFormData.cardName);
        setDescriptionInputVal(updateFormData.description);
        setVersionInputVal(updateFormData.version);
        setIsParentInputVal(updateFormData.parent);
        setCategoryIdInputVal(updateFormData.categoryId);

    }, []);

    const onChangeInputHandler = (event, inputType) => {
        if (inputType == 'level') {
            var index = event.target.selectedIndex;
            var optionElement = event.target.childNodes[index]
            var option = optionElement.getAttribute('data-id');
        }

        switch (inputType) {

            case 'level':
                setLevelInputVal(event.target.value)
                if (event.target.value) {
                    setLevelError(false)
                } else {
                    setLevelError(true)
                }
                break;

            case 'cardName':
                setCardNameInputVal(event.target.value)
                if (event.target.value) {
                    setCardNameError(false)
                } else {
                    setCardNameError(true)
                }
                break;

            case 'description':
                setDescriptionInputVal(event.target.value)
                if (event.target.value) {
                    setDescriptionError(false)
                } else {
                    setDescriptionError(true)
                }
                break;


            case 'version':
                setVersionInputVal(event.target.value);
                setVersionError(false)
                break;

            case 'category':
                setCategoryIdInputVal(event.target.value)
                if (event.target.value) {
                    setCategoryError(false)
                } else {
                    setCategoryError(true)
                }
                break;

            case 'isParent':
                setIsParentInputVal(event.target.checked)
                break;
        }
    }

    const handelAlertView = () => {
        setAlertOpen(!alertOpen);
    }


    const handelAlertClose = () => {
        setAlertOpen(!alertOpen);
    }

    function addProductHandler() {

        if (levelInputVal === "Level Option") { setLevelError(true); } else { setLevelError(false) }
        if (cardNameInputVal === "") { setCardNameError(true); } else { setCardNameError(false) }
        if (descriptionInputVal === "") { setDescriptionError(true); } else { setDescriptionError(false) }
        if (versionInputVal === "") { setVersionError(true); } else { setVersionError(false) }
        if (categoryIdInputVal === "Category Options") { setCategoryError(true); } else { setCategoryError(false) }


        if (levelInputVal != "Level Option" && cardNameInputVal && versionInputVal && descriptionInputVal && categoryIdInputVal != 'Category Options') {
            setShowLoader(true);
            setLevelInputVal('Level Option');
            setCardNameInputVal('');
            setDescriptionInputVal('');
            setVersionInputVal('');
            setCategoryIdInputVal('Category Options');

            axios({
                method: 'put',
                url: `${API.baseURL}ScoreCards/scorecards/${Number(props.updateForm.id)}`,
                data: {
                    id: Number(props.updateForm.id),
                    levelId: String(levelInputVal),
                    cardName: String(cardNameInputVal),
                    description: String(descriptionInputVal),
                    version: parseInt(versionInputVal),
                    categoryId: categoryIdInputVal,
                    isParent: isParentInputVal,
                    statusId: 1
                }

            }).then(function (response) {
                console.log(response);
                setShowLoader(false);
                setAlertOpen(true);
                setAlertMessage("Score Card Updated Successfully");
                props.loadAllScoreCards();
            }).catch(function (error) {
                setShowLoader(false);
                console.log(error);
            });
        }
    }

    //export default function FullWidthGrid() {
    const classes = useStyles();

    const CheckboxComponent = withStyles({
        root: {
            //   color: Colors.yellow,
            '&$checked': {
                color: Colors.deloitte_green,
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" checked={isParentInputVal} {...props} onChange={(event) => onChangeInputHandler(event, 'isParent')} />);


    return (
        <div className={classes.root}>

            <AlertDialog handelAlertView={handelAlertView} handelAlertClose={handelAlertClose} alertOpen={alertOpen} alertMessage={alertMessage} />

            <Grid className={classes.gridContainer} container spacing={1}>

                <Grid item style={{ width: "25%" }}>
                    <div className={classes.inputLabel}>
                        Level
                    </div>
                    <Paper className={clsx(classes.paper, classes.selectInputPaper)}>

                        <select value={levelInputVal} className={classes.select} onChange={(event) => onChangeInputHandler(event, 'level')}>
                            <option disabled value="Level Options">
                                Level Options
                            </option>
                            {levelList}
                        </select>
                    </Paper>
                    {levelError ? (
                        <div className={classes.errorMessage}>
                            Level is required
                        </div>
                    ) : (
                        ""
                    )}
                </Grid>


                <Grid item style={{ width: "25%" }}>
                    <FormControl className={clsx(classes.textField)} variant="outlined">
                        <div className={classes.inputLabel}>
                            Profile Name
                        </div>
                        <OutlinedInput
                            value={cardNameInputVal}
                            className={classes.outlinedInputStyle}
                            id="outlined-adornment-weight"
                            onChange={(event) => onChangeInputHandler(event, 'cardName')}
                            aria-describedby="outlined-weight-helper-text"
                            labelWidth={0}
                        />
                    </FormControl>
                    {cardNameError ? (
                        <div className={classes.errorMessage}>
                            Profile Name is required
                        </div>
                    ) : (
                        ""
                    )}
                </Grid>

                <Grid item style={{ width: "25%" }}>
                    <FormControl className={clsx(classes.textField)} variant="outlined">
                        <div className={classes.inputLabel}>
                            Description
                        </div>
                        <OutlinedInput
                            value={descriptionInputVal}
                            className={classes.outlinedInputStyle}
                            id="outlined-adornment-weight"
                            onChange={(event) => onChangeInputHandler(event, 'description')}
                            aria-describedby="outlined-weight-helper-text"
                            labelWidth={0}
                        />
                    </FormControl>
                    {descriptionError ? (
                        <div className={classes.errorMessage}>
                            Description is required
                        </div>
                    ) : (
                        ""
                    )}
                </Grid>

                <Grid item style={{ width: "25%" }}>
                    <FormControl className={clsx(classes.textField)} variant="outlined">
                        <div className={classes.inputLabel}>
                            Version
                        </div>
                        <OutlinedInput
                            value={versionInputVal}
                            className={classes.outlinedInputStyle}
                            id="outlined-adornment-weight"
                            onChange={(event) => onChangeInputHandler(event, 'version')}
                            aria-describedby="outlined-weight-helper-text"
                            labelWidth={0}
                        />
                    </FormControl>
                    {versionError ? (
                        <div className={classes.errorMessage}>
                            Version is required
                        </div>
                    ) : (
                        ""
                    )}
                </Grid>

                <Grid item style={{ width: "25%" }}>
                    <div className={classes.inputLabel}>
                        Category
                    </div>
                    <Paper className={clsx(classes.paper, classes.selectInputPaper)}>

                        <select value={categoryIdInputVal} className={classes.select} onChange={(event) => onChangeInputHandler(event, 'category')}>
                            <option disabled value="Category Options">
                                Category Options
                            </option>
                            {categoryList}
                        </select>
                    </Paper>
                    {categoryError ? (
                        <div className={classes.errorMessage}>
                            Category is required
                        </div>
                    ) : (
                        ""
                    )}
                </Grid>

                <Grid item style={{ width: "10%", textAlign: 'left', }}>
                    {/* <FormControl className={clsx(classes.textField)} variant="outlined"> */}
                    <div className={classes.inputLabel}>
                        IsParent
                    </div>
                    {/* </FormControl> */}

                    <CheckboxComponent style={{ paddingLeft: 0, }} color="isParent" />
                </Grid>

                {/* <Grid item style={{ width: "25%", display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                    <Button variant="contained" className={classes.createEntitiesActionButtons} onClick={props.onEditDoneHandler}>Cancel</Button>
                    <Button variant="contained" onClick={() => addProductHandler()} className={classes.createEntitiesActionButtons} style={{ background: Colors.deloitte_green, color: 'white' }}>
                        Save
                    </Button>
                </Grid> */}
                <Grid item style={{ width: "100%", display: 'flex', justifyContent: 'start', marginTop: '2%' }}>
                    <Button variant="outlined" className={clsx(classes.createEntitiesActionButtons, classes.cancelButton, 'mx-2')} onClick={props.onHideCreatFormHandler}>Cancel</Button>
                    <Button variant="contained" type="submit" className={clsx(classes.createEntitiesActionButtons, classes.addButton, 'mx-2')} style={{ background: Colors.deloitte_green, color: 'white' }}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
export default UpdateScoreCards;