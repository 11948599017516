import * as React from 'react';
import Drawer from '../components/menu/Drawer';
import {Route, Redirect, Switch} from 'react-router-dom'
import HomePage from '../views/homePage/HomePage';
import Entities from '../views/entities/Entities';
import AccessManagement from '../views/access_management/AccessManagement';
import Configuration from '../views/configuration/Configuration';
import SignIn from '../views/signIn/SignIn';

import Parameters from '../views/parameters/Parameters';
import ParametersMaps from '../views/parametersMaps/ParametersMaps';
import Factors from '../views/factors/Factors';
import Integrations from '../views/integrations/Integrations';
import ScoreCards from '../views/scoreCards/ScoreCards';
import ScoreCardDetails from '../views/ScoreCardDetails/ScoreCardDetails';
import ManagedList from '../views/managedList/ManagedList';
import Nodes from '../views/nodes/Nodes';
import {useSelector} from "react-redux";
import Scale from '../views/Scale/Scale';

const Layout = (props) => {

  const {isLoggedIn} = useSelector(state => state.auth);

  return (
    <React.Fragment>
      <Drawer>
        <Switch>

          <Route path="/signIn" component={SignIn} />
          <Route exact path="/" component={HomePage} />
          {
            isLoggedIn ?
              <React.Fragment>
                <Route path="/entities" component={Entities} />
                <Route path="/parameters" component={Parameters} />
                <Route path="/parametersMaps" component={ParametersMaps} />
                <Route path="/factors" component={Factors} />
                <Route path="/integrations" component={Integrations} />
                <Route path="/scoreCards" component={ScoreCards} />
                <Route path="/cardDetails" component={ScoreCardDetails} />
                <Route path="/managedList" component={ManagedList} />
                <Route path="/access_management" component={AccessManagement} />
                <Route path="/configuration" component={Configuration} />
                <Route path="/scale" component={Scale} />
              </React.Fragment>
              : null
          }
          <Route render={() => <h1>404 Not Found</h1>} />
          <Redirect to="/signIn" />

        </Switch>
      </Drawer>
    </React.Fragment>
  )
};

export default Layout;