import React, { useState } from "react";
import Search from "./Search";
import { useStyles } from "./Search.config";



const SearchContainer = ({list, setList, loadAll, className}) => {
    const classes = useStyles();
    const [searchInputValue, setSearchInputValue] = useState("");

    const onChangeSearchHandler = (event) => {
        setSearchInputValue(event.target.value);
        if (event.target.value) {
          let filteredList = list.filter(item => item.Name.toLowerCase().includes(event.target.value.toLowerCase()));
          console.log(filteredList);
          setList(filteredList);
        } else {
          loadAll();
        }
      }


    return <Search className={className} onChangeSearchHandler={onChangeSearchHandler} classes={classes} searchInputValue={searchInputValue} />
}

export default React.memo(SearchContainer)