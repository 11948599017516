import React, { useState, useEffect, useReducer } from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Paper, Button, IconButton, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { BackupOutlined, TramOutlined } from "@material-ui/icons";
import TextInput from "../input/input";
import axios from "axios";
import API from "../../varaibles/baseApi";
import AlertDialog from "../AlertDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";

import Colors from "../../varaibles/colors";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "2%",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  },
  gridContainer: {
    // justifyContent: "flex-end",
    width: "100%",
    borderRadius: "15px",
    padding: "15px",
    background: "#fff",
  },
  select: {
    border: "none",
    outline: "none",
    width: "100%",
  },
  button: {
    lineHeight: 1.43,
    width: "100%",
    padding: "7px",
    color: "#005074",
    borderColor: "#005074",
    textTransform: "none",
    fontWeight: "bold",
  },
  buttonFilled: {
    lineHeight: 1.43,
    width: "100%",
    padding: "7px",
    color: "white",
    background: "#005074",
    // borderColor: "#005074",
    textTransform: "none",
  },
  errorMessage: {
    textAlign: "left",
    marginLeft: "4px",
    color: "red",
    fontSize: "12px",
  },
  textField: {
    width: '100%',
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
  },
  inputLabel: {
    marginBottom: "2%",
    // fontWeight: 700,
    textAlign: "left",
  },
  outlinedInputStyle: { background: "white", width: "100%", height: '41px', borderRadius: '10px' },
  selectInputPaper: {
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
    height: "41px",
    width: "99%",
    borderRadius: '10px'
  },
  createEntitiesActionButtons: { textTransform: 'none', width: '10%', height: '41px', borderRadius: '10px' },
  addButton: {
    backgroundColor: Colors.deloitte_green,
    color: Colors.deloitte_green,
  },
  cancelButton: {
    backgroundColor: '#fff',
    color: Colors.deloitte_green,
    borderColor: Colors.deloitte_green
  }
}));


const COLORS_LIST = ['Red', 'Green', 'Blue', 'White']

const scaleInitialState = {
  scaleName: "",
  minValue: 0,
  maxValue: 0,
  scaleColor: ""
}

const scaleReducer = (state, action) => {
  switch (action.type) {
    case "scaleName":
      return { ...state, scaleName: action.payload };
    case "minValue":
      return { ...state, minValue: action.payload }
    case "maxValue":
      return { ...state, maxValue: action.payload }
    case "scaleColor":
      return { ...state, scaleColor: action.payload }
    case "reset":
      return scaleInitialState;
    default:
      return state;
  }
}

function CreateScoreCardDetails(props) {
  /***************     INPUTS ERROR HANDLER      **************** */
  const [{ scaleName, minValue, maxValue, scaleColor }, scaleDataDispatch] = useReducer(scaleReducer, scaleInitialState);
  const [saveButtonClick, setSaveButtonClick] = useState();

  /***************     SELECTS INPUT OPTIONS HANDLER      **************** */

  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    const prevData = props.prevData;
    if (prevData) {
      const { scaleName, minValue, maxValue, scaleColor } = prevData;
      scaleDataDispatch({ type: "scaleName", payload: scaleName })
      scaleDataDispatch({ type: "minValue", payload: minValue })
      scaleDataDispatch({ type: "maxValue", payload: maxValue })
      scaleDataDispatch({ type: "scaleColor", payload: scaleColor })
    }
  }, [])

  const onChangeInputHandler = (event, inputType) => {
    scaleDataDispatch({ type: inputType, payload: event.target.value })
  };

  const handelAlertView = () => {
    setAlertOpen(!alertOpen);
  };

  const handelAlertClose = () => {
    setAlertOpen(!alertOpen);
  };

  function addProductHandler() {
    setSaveButtonClick(true);
    const id = props.prevData?.id || null;
    const method = props.prevData?.id ? 'put' : 'post';
    const url = props.prevData?.id ? `scoreScale/${id}` : 'scoreScale/addScale';

    const data = {
      minValue,
      maxValue,
      scaleName,
      scaleColor
    };

    if (props.prevData?.id) data.id = props.prevData?.id;

    if (scaleName && minValue && maxValue && scaleColor) {
      axios({
        method,
        url: API.baseURL + url,
        data
      })
        .then(function (response) {
          setAlertOpen(true);
          setAlertMessage(`Risk Profile Details ${props.prevData?.id ? "Updated" : "Added"} Successfully`);
          scaleDataDispatch({ type: "reset" })
          setSaveButtonClick(false);
          props.onEditDoneHandler();
          props.loadAllScoreCardDetails();
        })
        .catch(function (error) {

        });
    }
  }

  //export default function FullWidthGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AlertDialog
        handelAlertView={handelAlertView}
        handelAlertClose={handelAlertClose}
        alertOpen={alertOpen}
        alertMessage={alertMessage}
      />

      <Grid className={classes.gridContainer} container spacing={1}>
        <Grid item style={{ width: "25%" }}>
          <div className={classes.inputLabel}>Name</div>
          <OutlinedInput
            value={scaleName}
            className={classes.outlinedInputStyle}
            id="outlined-adornment-weight"
            onChange={(event) => onChangeInputHandler(event, "scaleName")}
            aria-describedby="outlined-weight-helper-text"
            labelWidth={0}
          />
          {!scaleName && saveButtonClick ? (
            <div className={classes.errorMessage}>Scale Name is required</div>
          ) : (
            ""
          )}
        </Grid>

        <Grid item style={{ width: "25%" }}>
          <div className={classes.inputLabel}>Minimum Value</div>
          <OutlinedInput
            value={minValue}
            className={classes.outlinedInputStyle}
            id="outlined-adornment-weight"
            onChange={(event) => { !isNaN(event.target.value) && onChangeInputHandler(event, "minValue") }}
            aria-describedby="outlined-weight-helper-text"
            labelWidth={0}
          />
          {!minValue & saveButtonClick ? (
            <div className={classes.errorMessage}>Minimum value is required</div>
          ) : (
            ""
          )}
        </Grid>

        <Grid item style={{ width: "25%" }}>
          <div className={classes.inputLabel}>Maximum Value</div>
          <OutlinedInput
            value={maxValue}
            className={classes.outlinedInputStyle}
            id="outlined-adornment-weight"
            onChange={(event) => { !isNaN(event.target.value) && onChangeInputHandler(event, "maxValue") }}
            aria-describedby="outlined-weight-helper-text"
            labelWidth={0}
          />
          {!maxValue && saveButtonClick ? (
            <div className={classes.errorMessage}>Maximum value is required</div>
          ) : (
            ""
          )}
        </Grid>

        <Grid item style={{ width: "25%" }}>
          <div className={classes.inputLabel}>Color</div>
          <Paper className={clsx(classes.paper, classes.selectInputPaper)}>
            <select value={scaleColor} className={classes.select} onChange={(event) => onChangeInputHandler(event, "scaleColor")}>
              <option disabled value="scaleColor">
                Scale Colors
              </option>
              {COLORS_LIST.map((color) => {
                return (<option value={color} data-id={color} key={color}>
                  {color}
                </option>)
              })}
            </select>
          </Paper>
          {!scaleColor && saveButtonClick ? (
            <div className={classes.errorMessage}>Scale color is required</div>
          ) : (
            ""
          )}
        </Grid>

        {/* <Grid
          item
          style={{
            width: "25%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "2%",
          }}
        >
          <Button
            variant="contained"
            className={classes.createEntitiesActionButtons}
            onClick={props.onHideCreatFormHandler}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => addProductHandler()}
            className={classes.createEntitiesActionButtons}
            style={{ background: Colors.deloitte_green, color: "white" }}
          >
            Save
          </Button>
        </Grid> */}

        <Grid item style={{ width: "100%", display: 'flex', justifyContent: 'start', marginTop: '2%' }}>
          <Button variant="outlined" className={clsx(classes.createEntitiesActionButtons, classes.cancelButton, 'mx-2')} onClick={props.onHideCreatFormHandler}>Cancel</Button>
          <Button variant="contained" onClick={() => addProductHandler()} className={clsx(classes.createEntitiesActionButtons, classes.addButton, 'mx-2')} style={{ background: Colors.deloitte_green, color: 'white' }}>
            Add
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
export default CreateScoreCardDetails;
