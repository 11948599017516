import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button, IconButton, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { BackupOutlined, TramOutlined } from "@material-ui/icons";
import TextInput from "../input/input";
import axios from "axios";
import API from "../../varaibles/baseApi";
import AlertDialog from "../AlertDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import DateFnsUtils from "@date-io/date-fns";

import Colors from "../../varaibles/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "2%",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  },
  gridContainer: {
    // justifyContent: "flex-end",
    width: "100%",
    borderRadius: "15px",
    padding: "15px",
    background: "#fff",
  },
  select: {
    border: "none",
    outline: "none",
    width: "100%",
  },
  button: {
    lineHeight: 1.43,
    width: "100%",
    padding: "7px",
    color: "#005074",
    borderColor: "#005074",
    textTransform: "none",
    fontWeight: "bold",
  },
  buttonFilled: {
    lineHeight: 1.43,
    width: "100%",
    padding: "7px",
    color: "white",
    background: "#005074",
    // borderColor: "#005074",
    textTransform: "none",
  },
  errorMessage: {
    textAlign: "left",
    marginLeft: "4px",
    color: "red",
    fontSize: "12px",
  },
  textField: {
    width: '100%',
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
  },
  inputLabel: {
    marginBottom: "2%",
    // fontWeight: 700,
    textAlign: "left",
  },
  outlinedInputStyle: { background: "white", width: "100%", height: '41px', borderRadius: '10px' },
  selectInputPaper: {
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
    height: "41px",
    width: "99%",
    borderRadius: '10px'

  },
  createEntitiesActionButtons: { textTransform: 'none', width: '10%', height: '41px', borderRadius: '10px' },
  addButton: {
    backgroundColor: Colors.deloitte_green,
    color: Colors.deloitte_green,
  },
  cancelButton: {
    backgroundColor: '#fff',
    color: Colors.deloitte_green,
    borderColor: Colors.deloitte_green
  }
}));

function CreateParameterDetails(props) {
  /***************     INPUTS ERROR HANDLER      **************** */
  const [showLoader, setShowLoader] = useState(false);

  const [nodeAPIError, setNodeAPIError] = useState(false);
  const [callingParameterError, setCallingParameterError] = useState(false);
  const [fromAPIError, setFromAPIError] = useState(false);
  const [
    sourceAPIParameterNameError,
    setSourceAPIParameterNameError,
  ] = useState(false);
  const [inputTypeError, setInputTypeError] = useState(false);

  const [nodeApisList, setNodeApisList] = useState([]);
  const [inputTypeList, setInputTypeList] = useState([]);

  /***************     INPUTS VALUES HANDLER      **************** */
  const [nodeAPIInputVal, setNodeAPIInputVal] = useState("Select Node API");
  const [callingParameterInputVal, setCallingParameterInputVal] = useState("");
  const [fromAPIInputVal, setFromAPIInputVal] = useState(
    "Select From Node API"
  );
  const [
    sourceAPIParameterNameInputVal,
    setSourceAPIParameterNameInputVal,
  ] = useState("");
  const [inputTypeInputVal, setInputTypeInputVal] = useState(
    "Select Input Type"
  );
  const [isUpdate, setIsUpdate] = useState(false);
  const [apiDetailsId, setApiDetailsId] = useState(0);
  /***************     SELECTS INPUT OPTIONS HANDLER      **************** */

  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const getNodeApiLockup = () => {
    axios
      .get(`${API.baseURL}nodeApis/LockupNodeApi`)
      .then((response) => {
        setNodeApisList(response.data);
      })
      .catch((error) => setNodeApisList([]));
  };
  const getInputTypeLockup = () => {
    axios
      .get(`${API.baseURL}InputTypes/LockupInputTypes`)
      .then((response) => {
        setInputTypeList(response.data);
      })
      .catch((error) => setInputTypeList([]));
  };
  useEffect(() => {
    getNodeApiLockup();
    getInputTypeLockup();
    if (props.data) {
      setInputTypeInputVal(props.data.inputTypeId);
      setSourceAPIParameterNameInputVal(props.data.sourceAPIParam);
      setFromAPIInputVal(props.data.fromAPIId);
      setCallingParameterInputVal(props.data.callingParamName);
      setNodeAPIInputVal(props.data.nodeApiId);
      setApiDetailsId(props.data.id);
      setIsUpdate(true);
    }
  }, []);

  const onChangeInputHandler = (event, inputType) => {
    console.log(event, inputType);
    switch (inputType) {
      case "nodeApi":
        setNodeAPIInputVal(event.target.value);
        if (event.target.value) {
          setNodeAPIError(false);
        } else {
          setNodeAPIError(true);
        }
        break;

      case "callingParameter":
        setCallingParameterInputVal(event.target.value);
        if (event.target.value) {
          setCallingParameterError(false);
        } else {
          setCallingParameterError(true);
        }
        break;

      case "fromAPI":
        setFromAPIInputVal(event.target.value);
        if (event.target.value) {
          setFromAPIError(false);
        } else {
          setFromAPIError(true);
        }
        break;

      case "sourceAPIParameterName":
        setSourceAPIParameterNameInputVal(event.target.value);
        if (event.target.value) {
          setSourceAPIParameterNameError(false);
        } else {
          setSourceAPIParameterNameError(true);
        }
        break;

      case "inputType":
        setInputTypeInputVal(event.target.value);
        if (event.target.value) {
          setInputTypeError(false);
        } else {
          setInputTypeError(true);
        }
        break;
    }
  };

  const handelAlertView = () => {
    setAlertOpen(!alertOpen);
  };

  const handelAlertClose = () => {
    setAlertOpen(!alertOpen);
  };

  const addProductHandler = () => {
    if (nodeAPIInputVal === "Select Node API") {
      setNodeAPIError(true);
    } else {
      setNodeAPIError(false);
    }
    if (callingParameterInputVal === "") {
      setCallingParameterError(true);
    } else {
      setCallingParameterError(false);
    }
    if (fromAPIInputVal === "Select From Node API") {
      setFromAPIError(true);
    } else {
      setFromAPIError(false);
    }
    if (sourceAPIParameterNameInputVal === "") {
      setSourceAPIParameterNameError(true);
    } else {
      setSourceAPIParameterNameError(false);
    }
    if (inputTypeInputVal === "Select Input Type") {
      setInputTypeError(true);
    } else {
      setInputTypeError(false);
    }

    if (
      nodeAPIInputVal != "Select Node API" &&
      callingParameterInputVal &&
      fromAPIInputVal != "Select From Node API" &&
      sourceAPIParameterNameInputVal &&
      inputTypeInputVal != "Select Input Type"
    ) {
      setShowLoader(true);
      setNodeAPIInputVal("Select Node API");
      setCallingParameterInputVal("");
      setFromAPIInputVal("Select From Node API");
      setSourceAPIParameterNameInputVal("");
      setInputTypeInputVal("Select Input Type");
      if (!isUpdate) {
        axios
          .post(`${API.baseURL}APIDetails`, {
            nodeApiId: nodeAPIInputVal,
            callingParamName: callingParameterInputVal,
            fromAPIId: fromAPIInputVal,
            sourceAPIParam: sourceAPIParameterNameInputVal,
            inputTypeId: inputTypeInputVal,
          })
          .then(function (response) {
            setShowLoader(false);
            setAlertOpen(true);
            setAlertMessage("Parameter Details Added Successfully");
            props.onHideCreatFormHandler();
            props.loadAllNodes();
          })
          .catch(function (error) {
            setShowLoader(false);
          });
      } else {
        axios
          .put(`${API.baseURL}APIDetails/${apiDetailsId}`, {
            id: apiDetailsId,
            nodeApiId: nodeAPIInputVal,
            callingParamName: callingParameterInputVal,
            fromAPIId: fromAPIInputVal,
            sourceAPIParam: sourceAPIParameterNameInputVal,
            inputTypeId: inputTypeInputVal,
          })
          .then(function (response) {
            setShowLoader(false);
            setAlertOpen(true);
            setAlertMessage("Parameter Details Updated Successfully");
            props.onHideCreatFormHandler();
            props.loadAllNodes();
          })
          .catch(function (error) {
            setShowLoader(false);
          });
      }
    }
  };

  //export default function FullWidthGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AlertDialog
        handelAlertView={handelAlertView}
        handelAlertClose={handelAlertClose}
        alertOpen={alertOpen}
        alertMessage={alertMessage}
      />

      <Grid className={classes.gridContainer} container spacing={1}>
        <Grid item style={{ width: "25%" }}>
          <div className={classes.inputLabel}>Node Api</div>
          <Paper className={clsx(classes.paper, classes.selectInputPaper)}>
            <select
              value={nodeAPIInputVal}
              className={classes.select}
              onChange={(event) => onChangeInputHandler(event, "nodeApi")}
            >
              <option value="Select Node API">Select Node API</option>
              {nodeApisList.map((api) => (
                <option value={api.id} key={api.id}>
                  {api.apiName}
                </option>
              ))}
            </select>
          </Paper>
          {nodeAPIError ? (
            <div className={classes.errorMessage}>Node Api is required</div>
          ) : (
            ""
          )}
        </Grid>

        <Grid item style={{ width: "25%" }}>
          <FormControl className={clsx(classes.textField)} variant="outlined">
            <div className={classes.inputLabel}>Calling Parameter</div>
            <OutlinedInput
              value={callingParameterInputVal}
              className={classes.outlinedInputStyle}
              id="outlined-adornment-weight"
              onChange={(event) =>
                onChangeInputHandler(event, "callingParameter")
              }
              aria-describedby="outlined-weight-helper-text"
              labelWidth={0}
            />
          </FormControl>
          {callingParameterError ? (
            <div className={classes.errorMessage}>
              Calling Parameter is required
            </div>
          ) : (
            ""
          )}
        </Grid>

        <Grid item style={{ width: "25%" }}>
          <div className={classes.inputLabel}>From API</div>
          <Paper className={clsx(classes.paper, classes.selectInputPaper)}>
            <select
              value={fromAPIInputVal}
              className={classes.select}
              onChange={(event) => onChangeInputHandler(event, "fromAPI")}
            >
              <option value="Select Node API">Select Node API</option>
              {nodeApisList.map((api) => (
                <option value={api.id} key={api.id}>
                  {api.apiName}
                </option>
              ))}
            </select>
          </Paper>
          {fromAPIError ? (
            <div className={classes.errorMessage}>From API is required</div>
          ) : (
            ""
          )}
        </Grid>

        <Grid item style={{ width: "25%" }}>
          <FormControl className={clsx(classes.textField)} variant="outlined">
            <div className={classes.inputLabel}>Source API Parameter</div>
            <OutlinedInput
              value={sourceAPIParameterNameInputVal}
              className={classes.outlinedInputStyle}
              id="outlined-adornment-weight"
              onChange={(event) =>
                onChangeInputHandler(event, "sourceAPIParameterName")
              }
              aria-describedby="outlined-weight-helper-text"
              labelWidth={0}
            />
          </FormControl>
          {sourceAPIParameterNameError ? (
            <div className={classes.errorMessage}>
              Source API Parameter NameInputVal is required
            </div>
          ) : (
            ""
          )}
        </Grid>

        <Grid item style={{ width: "25%" }}>
          <div className={classes.inputLabel}>Input Type</div>
          <Paper className={clsx(classes.paper, classes.selectInputPaper)}>
            <select
              value={inputTypeInputVal}
              className={classes.select}
              onChange={(event) => onChangeInputHandler(event, "inputType")}
            >
              <option value="Select Input Type">Select Input Type</option>
              {inputTypeList.map((api) => (
                <option value={api.id} key={api.id}>
                  {api.label}
                </option>
              ))}
            </select>
          </Paper>
          {inputTypeError ? (
            <div className={classes.errorMessage}>Input Type is required</div>
          ) : (
            ""
          )}
        </Grid>

        {/* <Grid
          item
          style={{
            width: "25%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "2%",
          }}
        >
          <Button
            variant="contained"
            className={classes.createEntitiesActionButtons}
            onClick={props.onHideCreatFormHandler}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => addProductHandler()}
            className={classes.createEntitiesActionButtons}
            style={{ background: Colors.deloitte_green, color: "white" }}
          >
            {isUpdate ? "Update" : "Save"}
          </Button>
        </Grid> */}
        <Grid item style={{ width: "100%", display: 'flex', justifyContent: 'start', marginTop: '2%' }}>
          <Button variant="outlined" className={clsx(classes.createEntitiesActionButtons, classes.cancelButton, 'mx-2')} onClick={props.onHideCreatFormHandler}>Cancel</Button>
          <Button variant="contained" onClick={() => addProductHandler()} className={clsx(classes.createEntitiesActionButtons, classes.addButton, 'mx-2')} style={{ background: Colors.deloitte_green, color: 'white' }}>
          {isUpdate ? "Update" : "Add"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
export default CreateParameterDetails;
