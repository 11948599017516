import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import SaveIcon from '@material-ui/icons/Save';

import Icon from '@material-ui/core/Icon';
import ArrowForward from '@material-ui/icons/ArrowForward';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Colors from '../../varaibles/colors'

import { useHistory } from "react-router-dom";

import { isEmail } from "validator";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/auth";

import {
    Paper,
    Grid,
    ListItemIcon,
    ListItemText,
    ListItem,
    Typography,
    Button,
    CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: '50px'
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '70%',
        display: 'block',
        margin: '3% 0%'
    },
    sectionHeight: {
        height: '85vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    sectionImgHeight: {
        height: '85vh',
    },
    imgStyle: {
        width: '100%',
        height: '100%'
    },
    sectionTextContainer: {
        textAlign: 'left'
    },
    inputLabel: {
        marginBottom: '2%',
        fontWeight: 700
    },
    signInTitle: {
        fontWeight: 600,
        fontSize: '2.5rem'
    },
    signInSubTitle: {
        color: '#6b6b6b',
        fontSize: '1.5rem',
        lineHeight: '1.2'
    },
    button: {
        margin: theme.spacing(1),
        width: '70%',
        background: Colors.deloitte_green,
        marginLeft: 0,
        color: 'white'
    },
    outlinedInputStyle: { background: 'white', width: '100%', height: '35px' },
    forgetPassStyle: {
        color: Colors.accessible_green,
        textTransform: 'none'
    },
    errorMessage: {
        color: 'red',
        textAlign: 'left',
        paddingLeft: '3px'
    },
    loginError: {
        background: '#e85655',
        color: 'white',
        padding: '2%',
        marginTop: '3%',
    },
    loginForm: {
        padding: '0% 14%'
    },
    loadingProgress: {
        color: Colors.deloitte_green,
        margin: 'auto',
        display: 'block'
    }
}));


const SignIn = (props) => {

    const classes = useStyles();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [emailError, setEmailError] = useState(true);
    const [passwordError, setPasswordError] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState("");


    const { loginError, user, isLoggedIn } = useSelector(state => state.auth);

    const history = useHistory();
    const dispatch = useDispatch();


    useEffect(() => {
        console.log("effect", loginError);
        console.log('user', user);
        setLoginErrorMessage(loginError);
        if (loginError == "") {
            history.push("/entities");
        }
    }, [loginError]);


    const onChangeEmail = (e) => {
        const username = e.target.value;
        setEmailError(false);
        setEmail(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPasswordError(false);
        setPassword(password);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const signIn = () => {
        // history.push("/entities");
        setLoading(true);
        if (!isEmail(email)) {
            setEmailErrorMessage("email address is not valid");
            setEmailError(true);
            setLoading(false);
        } else {
            setEmailErrorMessage("");
            setEmailError(false);
        }

        if (password == "") {
            setPasswordErrorMessage("password is not valid");
            setPasswordError(true);
            setLoading(false);
        } else {
            setPasswordErrorMessage("");
            setPasswordError(false);
        }
        if (!emailError && !passwordError) {
            dispatch(login(email, password))
                .then(() => {
                    console.log("after press login", loginError);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }

    const CheckboxComponent = withStyles({
        root: {
            //   color: Colors.yellow,
            '&$checked': {
                color: Colors.deloitte_green,
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);


    return (
        <div className={classes.root}>

            <Grid container spacing={3} className={classes.sectionGridContainer}>

                <Grid item xs={6} className={classes.sectionHeight}>
                    <div className={classes.sectionTextContainer}>
                        <h1 className={classes.signInTitle}>
                            Sign In
                        </h1>
                        <div className={classes.signInSubTitle}>
                            Customized Solutions for your business
                        </div>

                        {
                            loginErrorMessage ?
                                <div className={classes.loginError} >
                                    {loginErrorMessage}
                                </div>

                                : ""
                        }

                        <FormControl className={clsx(classes.textField)} variant="outlined">
                            <div className={classes.inputLabel}>
                                Email
                            </div>
                            <OutlinedInput
                                className={classes.outlinedInputStyle}
                                id="outlined-adornment-weight"
                                value={email}
                                onChange={onChangeEmail}
                                aria-describedby="outlined-weight-helper-text"
                                labelWidth={0}
                            />
                            <div className={classes.errorMessage}>
                                {emailErrorMessage}
                            </div>
                        </FormControl>

                        <FormControl className={clsx(classes.textField)} variant="outlined">
                            <div className={classes.inputLabel}>
                                Password
                            </div>
                            <OutlinedInput
                                className={classes.outlinedInputStyle}
                                type={showPassword ? 'text' : 'password'}
                                id="outlined-adornment-weight"
                                value={password}
                                onChange={onChangePassword}
                                aria-describedby="outlined-weight-helper-text"
                                labelWidth={0}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />

                            <div className={classes.errorMessage}>
                                {passwordErrorMessage}
                            </div>
                        </FormControl>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <FormControlLabel control={<CheckboxComponent color="primary" />} label="Remember me?" />
                            <Button className={classes.forgetPassStyle} >Forgot your password?</Button>
                        </div>
                        {loading ?
                            <CircularProgress className={classes.loadingProgress} />
                            :
                            <Button
                                variant="contained"
                                className={classes.button}
                                // endIcon={<ArrowForward style={{ fontSize: '21px', color: Colors.brown }} />}
                                onClick={signIn}
                            >
                                Sign In
                            </Button>
                        }

                    </div>
                </Grid>

                <Grid item xs={6} className={classes.sectionImgHeight}>
                    <img className={classes.imgStyle} src={require("../../assets/img/LoginBackground.png")} />
                </Grid>

            </Grid>
        </div>



    )
};

export default SignIn;