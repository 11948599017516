import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Grid,
    ListItemIcon,
    ListItemText,
    ListItem,
    Typography,
} from '@material-ui/core';
import Colors from '../../varaibles/colors'

import ArrowForward from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: '50px',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    sectionHeight: {
        height: '90vh',
        display: 'table'
    },
    sectionImgHeight: {
        height: '90vh',

    },
    imgStyle: {
        width: '100%',
        height: '100%'
    },
    multiImgsStyle: {
        filter: 'grayscale(1)',
        width: "100%",
        height: "100%",
    },
    sectionTextContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'left'
    },
    sectionGridContainer: {
        marginBottom: '2%'
    },
    sectionTitle: {
        color: Colors.gray,
        fontSize: '22px'
    },
    textHighlight: {
        backgroundColor: Colors.deloitte_green,
        color: 'white',
        fontSize: '24px',
        fontWeight: 700,
        padding: 10
    },
    readMoreStyle: {
        width: 'auto !important',
        position: 'absolute',
        padding: 0,
        '& .MuiListItemText-root span': {
            fontWeight: 'bold',
            paddingRight: '13px'
        },
        '& .MuiListItemIcon-root': {
            color: Colors.black
        }
    },
    coloredSectionTitle: {
        color: Colors.deloitte_green,
        fontSize: '24px',
        fontWeight: 700
    },
    newsSubSection: {
        lineHeight: 2,
        marginBottom: '2%',
        '& .newsSubSectionTitle': {
            fontWeight: 'bold',
            fontSize: '15px'
        }
    }
}));


const HomePage = (props) => {
    localStorage.setItem('loggedInUser', 0);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className='row'>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <img
                        className={classes.imgStyle}
                        src={require("../../assets/img/section1.png")} />
                </div>
                <div className={clsx(classes.sectionTextContainer, 'col-lg-6 col-md-12 col-sm-12')}>
                    <h1> <span className={classes.textHighlight}>Deloitte Score</span></h1>
                    <p className='mt-3'>
                        Risk management in customs involves the systematic identification, assessment, and mitigation of potential risks associated with international trade and customs processes. Utilizing data analysis, intelligence, and risk scoring systems allows customs authorities to prioritize inspections and resource allocation for higher-risk shipments. By focusing resources where they are needed most, customs agencies can enhance security, prevent illegal activities, and streamline the clearance of low-risk cargo. Effective risk management in customs plays a crucial role in maintaining the integrity of borders, promoting international trade, and ensuring compliance with import/export regulations.
                    </p>
                </div>
            </div>

            <div className='row'>
                <div className={clsx(classes.sectionTextContainer, 'col-lg-6 col-md-12 col-sm-12')}>
                    <h4 className={clsx(classes.sectionTitle, 'mb-3')}>About</h4>
                    <span className={clsx(classes.textHighlight)}> Deloitte Risk Score</span>
                    <p className="color-black margin-bottom-m mt-3">
                        A risk scoring system for customs to evaluate and rank the potential risks associated with international trade and customs processes. It assigns numerical scores to various risk factors, including shipment documentation, cargo origin, and compliance history. These scores enable customs authorities to prioritize inspections and resource allocation for higher-risk shipments. By quantifying risk, it aids in efficient and targeted risk management, reducing the likelihood of illicit activities, such as smuggling and customs fraud. Such systems enhance border security and facilitate smoother international trade by expediting the clearance of low-risk shipments while rigorously scrutinizing high-risk ones.
                    </p>
                </div>
                <div className={clsx(classes.multiImgsStyle, 'col-lg-6 col-md-12 col-sm-12')}>
                    <img className={classes.imgStyle} src={require("../../assets/img/SecondSection.png")} />
                </div>
            </div>

            <div className='row'>
                <div className={clsx(classes.multiImgsStyle, 'col-lg-6 col-md-12 col-sm-12')}>
                    <img className={classes.imgStyle} src={require("../../assets/img/FourthSection.png")} />
                </div>


                <div className={clsx(classes.sectionTextContainer, 'col-lg-6 col-md-12 col-sm-12')}>
                    <h1 className={classes.coloredSectionTitle}>Benefits</h1>
                    <p>
                        Deloitte Risk scoring systems tailored for customs administration offer a range of benefits. It enhances security by pinpointing high-risk shipments, allowing customs authorities to allocate resources effectively to combat smuggling, fraud, and other illicit activities. Additionally, the system streamlines trade processes by expediting customs clearance for low-risk shipments, fostering smoother international trade and reducing delays. It also promote compliance with import/export regulations, reducing instances of non-compliance and ensuring fair competition. Furthermore, Deloitte risk scoring system empowers customs officials with data-driven insights, enabling them to make informed decisions and adapt strategies to address evolving threats effectively.
                    </p>
                </div>
            </div>

        </div>
    );
};

export default HomePage;