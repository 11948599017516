import {fade} from "@material-ui/core/styles";
import Colors from "../../varaibles/colors";

export const createStyle = (theme) => ({
  root: {
    width: "100%",
  },
  baseRootPageStyle: {
    // background: "white",
    // padding: "15px",
    // borderRadius: "15px",
    // boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.04)",
    // marginTop: '50px'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    borderRadius: 0,
  },
  table: {
    minWidth: 750,
    "& tr:nth-child(even)": {
      backgroundColor: "#fafafa",
    },
    "& tr:hover": {
      backgroundColor: "#fafafa",
    },
    "& tbody .MuiTableRow-root.Mui-selected": {
      backgroundColor: Colors.lightYellow,
    },
    "& tbody .MuiCheckbox-colorSecondary.Mui-checked": {
      color: Colors.yellow,
    },
    padding: '20px'
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableHeaderStyle: {
    // borderBottom: "1px solid rgba(224, 224, 224, 1)",
    background: "#fff",
  },
  TablePaginationStyle: {
    "& p:first-of-type": {
      display: "none",
    },
    "& .MuiTablePagination-selectRoot": {
      display: "none",
    },
  },
  sizeCellContainer: {
    textAlign: "center",
    background: "#8498c9",
    color: "white",
    padding: "4px",
    width: "70%",
    borderRadius: "4px",
  },
  tableContainer: {
    maxHeight: 260,
  },
  sectionTitle: {
    textAlign: "left",
    fontSize: "20px",
    fontWeight: "bold",
  },
  removeBtn: {
    background: "#b40100",
    textTransform: "none",
    color: "white",
  },
  transactionActionsContainer: {
    textAlign: "right",
  },
  button: {
    lineHeight: 1.43,
    width: "20%",
    color: "#005074",
    borderColor: "#005074",
    textTransform: "none",
    fontWeight: "bold",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
    border: "1px solid darkgrey",
    borderRadius: "12px",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: Colors.black,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  menuIconStyle: {
    width: "16%",
    filter: 'brightness(0)'
  },
  tableHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2%",
  },
  actions: {
    cursor: 'pointer'
  }
})