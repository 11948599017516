import axios from "axios";
import API from "../varaibles/baseApi";

const API_URL = "http://localhost:8080/api/auth/";

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = async(email, password) => {
  const response = {
    status: 200,
    data: {
      accessToken: 'test'
    }
  }

  sessionStorage.setItem("user", JSON.stringify(response.data));

  return response.data
  
  // return axios
  //   .post(API.baseURL + "Users/Login", {
  //     Email: email,
  //     Password: password,
  //     RememberMe:true
  //   })
  //   .then((response) => {
  //     console.log(response);
  //     if (response.data) {
  //       console.log(response.data);
  //       sessionStorage.setItem("user", JSON.stringify(response.data));
  //     }else{
  //       console.log(response);
  //       sessionStorage.setItem("user", JSON.stringify(response));
  //     }
  //     return response.data;
  //   });
};

const logout = () => {
  sessionStorage.removeItem("user");
};

export default {
  register,
  login,
  logout,
};
