import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../varaibles/colors';

export const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        backgroundColor: 'white',
        marginLeft: 0,
        width: '33%',
        height: '41px',
        // border: '1px solid darkgrey',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    searchIcon: {
        padding: theme.spacing(0, 3),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: Colors.black
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    searchButton: {
        backgroundColor: Colors.deloitte_green,
        color: 'white',
        borderRadius: '0 10px 10px 0'
    }
}));