import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, fade } from "@material-ui/core/styles";
import Loader from "../../components/Loader";
import Colors from "../../varaibles/colors";
import PropTypes from "prop-types";
import {
  MoreHoriz,
  Search,
  MoreVert
} from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import API from "../../varaibles/baseApi";
import axios from "axios";
import EnhancedTableHead from "../../components/EnhancedTableHead/EnhancedTableHead.js";
import AlertDialog from "../../components/AlertDialog.js";
import {
  InputBase,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
  OutlinedInput,
} from "@material-ui/core";
import { color } from "ansi-styles";
import colors from "../../varaibles/colors";
import { SectionHeader } from '../../components/SectionHeader';
import SearchIcon from '../../assets/icons/search.svg'



const mappingHeadCells = [
  {
    id: "Id",
    numeric: false,
    disablePadding: true,
    label: "Id",
  },
  {
    id: "parameterName",
    numeric: true,
    disablePadding: false,
    label: "Parameter Name",
  },
  {
    id: "Api Name",
    numeric: true,
    disablePadding: false,
    label: "Code",
  },
  {
    id: "node",
    numeric: true,
    disablePadding: false,
    label: "Node",
  },
  {
    id: "parentNode",
    numeric: true,
    disablePadding: false,
    label: "Parent Node",
  },
  {
    id: "function",
    numeric: true,
    disablePadding: false,
    label: "Function",
  },
];
const parameterHeadCells = [
  {
    id: "parameterId",
    numeric: false,
    disablePadding: true,
    label: "parameterId",
  },
  {
    id: "cardName",
    numeric: true,
    disablePadding: false,
    label: "Profile Name",
  },
  {
    id: "parameterName",
    numeric: true,
    disablePadding: false,
    label: "Parameter Name",
  },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: '10px'
  },
  baseRootPageStyle: {
    background: "white",
    padding: "15px",
    // borderRadius: "15px",
    // boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.04)",
    marginBottom: "15px",
    // marginTop: '50px'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    borderRadius: 0,
  },
  table: {
    "& tr:nth-child(even)": {
      backgroundColor: "#fafafa",
    },
    "& tr:hover": {
      backgroundColor: "#fafafa",
    },
    "& tbody .MuiTableRow-root.Mui-selected": {
      backgroundColor: Colors.lightYellow,
    },
    "& tbody .MuiCheckbox-colorSecondary.Mui-checked": {
      color: Colors.yellow,
    },
    padding: '20px'

  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableHeaderStyle: {
    // borderBottom: "1px solid rgba(224, 224, 224, 1)",
    background: "#fff",
  },
  TablePaginationStyle: {
    "& p:first-of-type": {
      display: "none",
    },
    "& .MuiTablePagination-selectRoot": {
      display: "none",
    },
  },
  sizeCellContainer: {
    textAlign: "center",
    background: "#8498c9",
    color: "white",
    padding: "4px",
    width: "70%",
    borderRadius: "4px",
  },
  tableContainer: {
    maxHeight: 350,
  },
  sectionTitle: {
    textAlign: "left",
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "2%",
  },
  removeBtn: {
    background: "#b40100",
    textTransform: "none",
    color: "white",
  },
  transactionActionsContainer: {
    textAlign: "right",
  },
  button: {
    lineHeight: 1.43,
    width: "20%",
    color: "#005074",
    borderColor: "#005074",
    textTransform: "none",
    fontWeight: "bold",
  },
  scoreCardsContainer: {
    padding: "20px",
    marginBottom: "20px",
    borderRadius: "15px",
    boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.04)",
    backgroundColor: "#ffffff",
  },
  mappingContainer: {
    padding: "20px",
    marginBottom: "10px",
    borderRadius: "15px",
    backgroundColor: "#ffffff",
    height: "388px",
  },
  noMarginTop: {
    marginTop: "0px",
  },
  inputLabel: {
    marginBottom: "2%",
    fontWeight: 700,
    textAlign: "left",
  },
  select: {
    border: "none",
    outline: "none",
    width: "100%",
  },
  selectInputPaper: {
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
    height: "35px",
    width: "99%",
  },
  createEntitiesActionButtons: {
    textTransform: "none",
    width: "45%",
    fontWeight: 700,
    backgroundColor: Colors.deloitte_green,
    color: "white",
  },
  createEntitiesDisabledActionButtons: {
    textTransform: "none",
    width: "45%",
    fontWeight: 700,
    backgroundColor: Colors.cool_gray,
    color: "white",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
    border: "1px solid darkgrey",
    borderRadius: "12px",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: Colors.black,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  menuIconStyle: {
    width: "16%",
    filter: 'brightness(0)'
  },
  tableHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2%",
  },
}));

const ParameterMaps = (props) => {
  const [parameterSelectedRow, setParameterSelectedRow] = useState([]);

  const [parameterOrder, setParameterOrder] = useState("asc");
  const [parameterOrderBy, setParameterOrderBy] = useState("id");

  const handleParameterRequestSort = (event, property) => {
    const isAsc = parameterOrderBy === property && parameterOrder === "asc";
    setParameterOrder(isAsc ? "desc" : "asc");
    setParameterOrderBy(property);
  };

  const handleParameterTableRowClick = (event, ID) => {
    const selectedIndex = parameterSelectedRow.indexOf(ID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(parameterSelectedRow, ID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(parameterSelectedRow.slice(1));
    } else if (selectedIndex === parameterSelectedRow.length - 1) {
      newSelected = newSelected.concat(parameterSelectedRow.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        parameterSelectedRow.slice(0, selectedIndex),
        parameterSelectedRow.slice(selectedIndex + 1)
      );
    }
    setParameterSelectedRow(newSelected);
  };
  const isSelectedParameter = (name) =>
    parameterSelectedRow.indexOf(name) !== -1;

  //-------------------------------------------------------------------------------------

  const [mappingSelectedRow, setMappingSelectedRow] = useState([]);
  const [nodesParentsList, setNodesParentsList] = useState([]);

  const [mappingOrder, setMappingOrder] = useState("asc");
  const [mappingOrderBy, setMappingOrderBy] = useState("id");

  const handleMappingRequestSort = (event, property) => {
    const isAsc = mappingOrderBy === property && mappingOrder === "asc";
    setMappingOrder(isAsc ? "desc" : "asc");
    setMappingOrderBy(property);
  };

  const handleMappingTableRowClick = (event, ID) => {
    const selectedIndex = mappingSelectedRow.indexOf(ID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(mappingSelectedRow, ID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(mappingSelectedRow.slice(1));
    } else if (selectedIndex === mappingSelectedRow.length - 1) {
      newSelected = newSelected.concat(mappingSelectedRow.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        mappingSelectedRow.slice(0, selectedIndex),
        mappingSelectedRow.slice(selectedIndex + 1)
      );
    }
    setMappingSelectedRow(newSelected);
  };
  const handleMappingTableRowClick2 = (event, index) => {
    let newSelected = nodesParentsList;
    newSelected[index].isSelected = !newSelected[index].isSelected;
    setNodesParentsList(newSelected);
    setRefresh(!refresh);
  };
  const isSelectedMapping = (name) => mappingSelectedRow.indexOf(name) !== -1;
  const isSelectedMapping2 = (index) => nodesParentsList[index].isSelected;

  //-------------------------------------------------------------------------------------
  const [showLoader, setShowLoader] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [parameterList, setParameterList] = useState([]);
  const [mappingList, setMappingList] = useState([]);
  const [nodeList, setNodeList] = useState([]);
  const [methodList, setMethodList] = useState([]);
  const [operationList, setOperationList] = useState([]);
  const [selectedNode, setSelectedNode] = useState(0);
  const [selectedMethod, setselectedMethod] = useState(0);
  const [selectedOperation, setselectedOperation] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);

  const loadAllNodes = () => {
    axios.get(API.baseURL + "nodes").then((response) => {
      setNodeList(response.data);
    });
  };
  const loadAllScoreCardDetails = () => {
    axios.get(API.baseURL + "CardDetails/apiCardDetails").then((response) => {
      setParameterList(response.data);
    });
  };
  const loadAllParamterMaps = () => {
    axios
      .get(API.baseURL + "ParametersMaps/apiParametersMaps")
      .then((response) => {
        setMappingList(response.data);
      });
  };
  const loadAllOperations = () => {
    axios.get(API.baseURL + "Operations/apiOperations").then((response) => {
      setOperationList(response.data);
    });
  };

  useEffect(() => {
    loadAllNodes();
    loadAllScoreCardDetails();
    loadAllParamterMaps();
    loadAllOperations();
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const getNodeApis = (nodeId) => {
    axios
      .get(`${API.baseURL}nodeApis/apiNodeApisbyNodeId/${nodeId}`)
      .then((response) => {
        setMethodList(response.data);
      })
      .catch((error) => {
        setMethodList([]);
        setselectedMethod("");
      });
  };
  const getNodesMapping = (nodeApiId) => {
    let binaryXML = methodList.filter((item) => item.id == nodeApiId)[0]
      .binaryXml;
    axios
      .post(
        `${API.baseURL}Integration/GetNodesMapping`,
        {binaryXml: binaryXML},
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => setNodesParentsList(res.data));
  };
  const onChangeInputHandler = (event, inputType) => {
    switch (inputType) {
      case "WebServices":
        setSelectedNode(event.target.value);
        getNodeApis(event.target.value);
        break;

      case "method":
        setselectedMethod(event.target.value);
        getNodesMapping(event.target.value);
        break;

      case "operation":
        setselectedOperation(event.target.value);
        break;
    }
  };
  const mappingHandler = () => {
    let row = nodesParentsList.filter((item) => item.isSelected)[0];
    axios
      .post(`${API.baseURL}ParametersMaps/AddParameterMap`, {
        nodeApiid: selectedMethod,
        operationId: selectedOperation,
        nodeId: selectedNode,
        parameterId: parameterSelectedRow[0],
        xmlParent: row.parentNodeName,
        xmlNode: row.nodeName,
      })
      .then((response) => {
        setShowLoader(false);
        setAlertOpen(true);
        setAlertMessage("Mapping is added successfully");
        loadAllParamterMaps();
      })
      .catch((error) => {
        setShowLoader(false);
        console.log(error);
      });
  };
  const handelAlertView = () => {
    setAlertOpen(!alertOpen);
  };
  const onChangeSearchHandler = (event) => {
    setSearchInputValue(event.target.value);
    console.log(event.target.value);
    if (event.target.value) {
      let filteredList = nodeList.filter((item) =>
        item.name.includes(event.target.value)
      );
      console.log(filteredList);
      setNodeList(filteredList);
    } else {
      loadAllNodes();
    }
  };
  const onMenuMultipleDelete = () => {
    handleMenuClose();
    setConfirmOpen(true);
    setConfirmMessage("Do you really want to delete these items?");

    const itemsDeletedList = () => {
      axios
        .delete(API.baseURL + "Nodes/MultipleDeleteNodes", {
          data: parameterSelectedRow,
        })
        .then(function (response) {
          setAlertOpen(true);
          setAlertMessage("Deleted Successfully");
          setConfirmOpen(false);
          loadAllNodes();
        })
        .catch(function (error) {
          setAlertOpen(true);
          setAlertMessage(error);
          setConfirmOpen(false);
        });
    };
  };

  const isMapDisabled = () => !(selectedMethod &&
    selectedNode &&
    parameterSelectedRow[0] &&
    selectedOperation);

  return (
    // <EnhancedTable />
    <>
      <AlertDialog
        handelAlertView={handelAlertView}
        handelAlertClose={handelAlertView}
        alertOpen={alertOpen}
        alertMessage={alertMessage}
      />

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={6}>
          <div className={classes.baseRootPageStyle}>
            <Loader showLoader={showLoader} />
            <div className={classes.tableHeaderContainer}>
              <div className={classes.sectionTitle}>Parameters</div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <img src={SearchIcon} />
                  </div>
                  <InputBase
                    value={searchInputValue}
                    placeholder="Search"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onChange={onChangeSearchHandler}
                  />
                </div>
                {/* <IconButton
                  disabled
                  onClick={handleMenuClick}
                  style={{ marginLeft: "10px", color: Colors.black }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  component="span"
                >
                  <MoreVert />
                </IconButton> */}

                {/* <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleMenuClose}>
                    <img
                      className={classes.menuIconStyle}
                      src={require("../../assets/img/Export.png")}
                    />

                    <div style={{ marginLeft: "8px" }}>Export</div>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <img
                      className={classes.menuIconStyle}
                      src={require("../../assets/img/Import.png")}
                    />

                    <div style={{ marginLeft: "8px" }}>Import</div>
                  </MenuItem>
                  {parameterSelectedRow.length > 0 ? (
                    <MenuItem onClick={onMenuMultipleDelete}>
                      <img
                        className={classes.menuIconStyle}
                        src={require("../../assets/img/Delete-orange.png")}
                      />
                      <div style={{ marginLeft: "8px" }}>Delete</div>
                    </MenuItem>
                  ) : (
                    ""
                  )}
                </Menu> */}
              </div>
            </div>
            <div className={classes.mappingContainer}>
              <Paper className={classes.paper}>
                <TableContainer className={classes.tableContainer}>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="medium"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <EnhancedTableHead
                      headCells={parameterHeadCells}
                      classes={classes}
                      order={parameterOrder}
                      orderBy={parameterOrderBy}
                      onRequestSort={handleParameterRequestSort}
                    />

                    {parameterList.length > 0 ? (
                      <TableBody>
                        {stableSort(
                          parameterList,
                          getComparator(parameterOrder, parameterOrderBy)
                        ).map((row, index) => {
                          const isItemSelected = isSelectedParameter(
                            row.parameterId
                          );
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                              aria-checked={isItemSelected}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  onClick={(event) =>
                                    handleParameterTableRowClick(event, row.parameterId)
                                  }
                                  checked={isItemSelected}
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </TableCell>

                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                              >
                                {row.id}
                              </TableCell>
                              <TableCell>{row.scoreCard.cardName}</TableCell>
                              <TableCell>
                                {row.parameter
                                  ? row.parameter.ParameterName
                                  : ""}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            style={{ textAlign: "center" }}
                          >
                            {" "}
                            There Are No Entities
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className={classes.baseRootPageStyle}>
            <div
              className={classes.mappingContainer}
              style={{ height: "444px" }}
            >
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={4} className="text-left">
                  <label className={classes.inputLabel}>Web Services</label>
                </Grid>
                <Grid item xs={8}>
                  <select
                    value={selectedNode}
                    className="form-control"
                    onChange={(event) =>
                      onChangeInputHandler(event, "WebServices")
                    }
                  >
                    <option disabled value="0">
                      Web service Options
                    </option>
                    {nodeList.map((node) => (
                      <option key={node.id} value={node.id}>
                        {node.name}
                      </option>
                    ))}
                  </select>
                </Grid>
              </Grid>
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={4} className="text-left">
                  <label className={classes.inputLabel}>Method</label>
                </Grid>
                <Grid item xs={8}>
                  <select
                    value={selectedMethod}
                    className="form-control"
                    onChange={(event) => onChangeInputHandler(event, "method")}
                  >
                    <option disabled value="0">
                      Method Options
                    </option>
                    {methodList.map((method) => (
                      <option key={method.id} value={method.id}>
                        {method.apiName}
                      </option>
                    ))}
                  </select>
                </Grid>
              </Grid>
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={4} className="text-left">
                  <label className={classes.inputLabel}>Mapping Title</label>
                </Grid>
                <Grid item xs={8}>
                  <select
                    value={selectedOperation}
                    className="form-control"
                    onChange={(event) =>
                      onChangeInputHandler(event, "operation")
                    }
                  >
                    <option disabled value="0">
                      Operation Options
                    </option>
                    {operationList.map((method) => (
                      <option key={method.id} value={method.id}>
                        {method.label}
                      </option>
                    ))}
                  </select>
                </Grid>
              </Grid>
              <Grid container className={classes.root}>
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: "260px" }}
                >
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Select</TableCell>
                        <TableCell>Node</TableCell>
                        <TableCell>Parent</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {nodesParentsList.map((row, index) => {
                        const isItemSelected = isSelectedMapping2(index);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            aria-checked={isItemSelected}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) =>
                                  handleMappingTableRowClick2(event, index)
                                }
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell>{row.nodeName}</TableCell>
                            <TableCell>{row.parentNodeName}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="text-right mb-3">
        <Button
          disabled={(isMapDisabled())}
          className={isMapDisabled() ? [classes.createEntitiesDisabledActionButtons, "col-3"].join(" ") : [classes.createEntitiesActionButtons, "col-3"].join(" ")}
          onClick={mappingHandler}
        >
          Map
        </Button>
      </div>
      <div className="w-100 mb-0">
      <div className={classes.sectionTitle}>Mapping</div>
              <SectionHeader list={nodeList} setList={setNodeList} loadAll={loadAllNodes} hideIcons={{
                add: true,
                delete: true
              }} />
            </div>
      <div className={classes.baseRootPageStyle}>
        <Loader showLoader={showLoader} />
        <div className="container">
          <div className={classes.tableHeaderContainer}>
            {/* <div class="row">
              <div className="col-4">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <Search />
                    </div>
                    <InputBase
                      value={searchInputValue}
                      placeholder="Search"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ "aria-label": "search" }}
                      onChange={onChangeSearchHandler}
                    />
                  </div>
                  <IconButton
                    onClick={handleMenuClick}
                    style={{ marginLeft: "10px", color: Colors.black }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    component="span"
                  >
                    <MoreVert />
                  </IconButton>

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleMenuClose}>
                      <img
                        className={classes.menuIconStyle}
                        src={require("../../assets/img/Export.png")}
                      />

                      <div style={{ marginLeft: "8px" }}>Export</div>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                      <img
                        className={classes.menuIconStyle}
                        src={require("../../assets/img/Import.png")}
                      />

                      <div style={{ marginLeft: "8px" }}>Import</div>
                    </MenuItem>
                    {mappingSelectedRow.length > 0 ? (
                      <MenuItem onClick={onMenuMultipleDelete}>
                        <img
                          className={classes.menuIconStyle}
                          src={require("../../assets/img/Delete-orange.png")}
                        />
                        <div style={{ marginLeft: "8px" }}>Delete</div>
                      </MenuItem>
                    ) : (
                      ""
                    )}
                  </Menu>
                </div>
              </div>
            </div> */}
            
          </div>
        </div>
        <Paper className={classes.paper}>
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              stickyHeader
              aria-label="sticky table"
            >
              <EnhancedTableHead
                headCells={mappingHeadCells}
                classes={classes}
                order={mappingOrder}
                orderBy={mappingOrderBy}
                onRequestSort={handleMappingRequestSort}
              />

              {mappingList.length > 0 ? (
                <TableBody>
                  {stableSort(
                    mappingList,
                    getComparator(mappingOrder, mappingOrderBy)
                  ).map((row, index) => {
                    const isItemSelected = isSelectedMapping(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) =>
                              handleMappingTableRowClick(event, row.id)
                            }
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>

                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="default"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell>{row.parameterName}</TableCell>
                        <TableCell> {row.nodeApiName}</TableCell>
                        <TableCell>{row.xmlNode}</TableCell>
                        <TableCell> {row.xmlParent} </TableCell>
                        <TableCell> {row.operationName} </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: "center" }}>
                      {" "}
                      There Are No Entities
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};

export default ParameterMaps;
