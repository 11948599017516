import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import axios from 'axios';
import API from '../../varaibles/baseApi';
import AlertDialog from '../AlertDialog';
import Colors from "../../varaibles/colors";
import { Formik, Form, Field, ErrorMessage } from "formik";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: "2%",
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        boxShadow: "none",
    },
    gridContainer: {
        justifyContent: "flex-end",
        width: "100%",
        borderRadius: "15px",
        padding: "15px",
        background: "#fff",
    },
    select: {
        border: "none",
        outline: "none",
        width: "100%",
    },
    button: {
        lineHeight: 1.43,
        width: "100%",
        padding: "7px",
        color: "#005074",
        borderColor: "#005074",
        textTransform: "none",
        fontWeight: "bold",
    },
    buttonFilled: {
        lineHeight: 1.43,
        width: "100%",
        padding: "7px",
        color: "white",
        background: "#005074",
        // borderColor: "#005074",
        textTransform: "none",
    },
    errorMessage: {
        textAlign: "left",
        marginLeft: "4px",
        color: "red",
        fontSize: "12px",
    },
    textField: {
        width: '100%',
        underline: {
            "&&&:before": {
                borderBottom: "none",
            },
            "&&:after": {
                borderBottom: "none",
            },
        },
    },
    inputLabel: {
        marginBottom: "2%",
        // fontWeight: 700,
        textAlign: "left",
    },
    outlinedInputStyle: { background: "white", width: "100%", height: '41px', borderRadius: '10px' },
    selectInputPaper: {
        border: "1px solid rgba(0, 0, 0, 0.23) !important",
        height: "41px",
        width: "99%",
        borderRadius: '10px'
    },
    createEntitiesActionButtons: { textTransform: 'none', width: '10%', height: '41px', borderRadius: '10px' },
    addButton: {
        backgroundColor: Colors.deloitte_green,
        color: Colors.deloitte_green,
    },
    cancelButton: {
        backgroundColor: '#fff',
        color: Colors.deloitte_green,
        borderColor: Colors.deloitte_green
    }
}));


const UpdateParameter = (props) => {

    const classes = useStyles();
    const [showLoader, setShowLoader] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [FactorsConditionsList, setFactorsConditionsList] = useState([]);
    const [typesList, setTypesList] = useState([]);

    useEffect(() => {
        console.log("row", props.updateForm);
        axios
            .get("" + API.baseURL + "conditions")
            .then((response) => {
                let ConditionsList = response.data.map((Condition) => (
                    <option value={Condition.id} data-id={Condition.conditionName} key={Condition.id}>{Condition.conditionName}</option>
                ));
                setFactorsConditionsList(ConditionsList);
            });

        axios
            .get("" + API.baseURL + "InputTypes/LockupInputTypes")
            .then((response) => {
                let InputTypesList = response.data.map((item) => (
                    <option value={item.id} data-id={item.label} key={item.id}>{item.label}</option>
                ));
                setTypesList(InputTypesList);
            });

    }, [props]);


    const handelAlertView = () => {
        setAlertOpen(!alertOpen);
    }

    const handelAlertClose = () => {
        setAlertOpen(!alertOpen);
    }

    const onSubmit = (values, { resetForm }) => {
        console.log("Subb", values);
        axios({
            method: 'put',
            url: `${API.baseURL}Parameters/${Number(props.updateForm.id)}`,

            data: {
                id: Number(props.updateForm.id),
                parameterName: String(values.parameterName),
                whatif: String(values.whatIfAnalysis),
                conditionId: parseInt(values.factorsCondition),
                inputTypeId: parseInt(values.parameterType),
                enitityId: values.enitityId,
            }

        }).then(function (response) {
            console.log(response);
            setShowLoader(false);
            setAlertOpen(true);
            setAlertMessage("Parameter Updated Successfully");
            props.loadAllParameters();
            props.onEditDoneHandler();
        }).catch(function (error) {
            setShowLoader(false);
            console.log(error);
        });

    };

    const validate = (values) => {
        const errors = {};
        if (!values.parameterName) {
            errors.parameterName = "Parameter Name is required";
        }
        if (values.parameterType === "") {
            errors.parameterType = "parameter Type is required";
        }
        if (values.factorsCondition === "") {
            errors.factorsCondition = "factors Condition is required";
        }
        if (values.whatIfAnalysis === "") {
            errors.whatIfAnalysis = "what If Analysis is required";
        }
        return errors;
    };

    return (
        <div className={classes.root}>
            <AlertDialog handelAlertView={handelAlertView} handelAlertClose={handelAlertClose} alertOpen={alertOpen} alertMessage={alertMessage} />

            <Formik
                validate={validate}
                onSubmit={onSubmit}
                initialValues={{
                    parameterName: props.updateForm.parameterName,
                    parameterType: props.updateForm.inputTypeId,
                    factorsCondition: props.updateForm.conditionId,
                    whatIfAnalysis: props.updateForm.whatif,
                    enitityId: props.updateForm.enitityId
                }}
            >
                {() => (
                    <Form>
                        <Grid className={classes.gridContainer} container spacing={1}>
                            <Grid item style={{ width: "25%" }}>
                                <div className={classes.inputLabel}>Parameter Name</div>
                                <Field
                                    className="form-control"
                                    type="text"
                                    name="parameterName"
                                ></Field>
                                <ErrorMessage
                                    name="parameterName"
                                    component="div"
                                    className={classes.errorMessage}
                                ></ErrorMessage>
                                <Field
                                    className="form-control"
                                    type="hidden"
                                    name="entityId"
                                ></Field>
                            </Grid>
                            <Grid item style={{ width: "25%" }}>
                                <div className={classes.inputLabel}>Parameter Type</div>
                                <Field
                                    as="select"
                                    name="parameterType"
                                    className="form-control"
                                >

                                    <option default Value="" >
                                        {props.updateForm.inputType}
                                    </option>
                                    {typesList}
                                </Field>
                                <ErrorMessage
                                    name="parameterType"
                                    component="div"
                                    className={classes.errorMessage}
                                ></ErrorMessage>
                            </Grid>

                            <Grid item style={{ width: "25%" }}>
                                <div className={classes.inputLabel}>Factors Condition</div>
                                <Field
                                    as="select"
                                    name="factorsCondition"
                                    className="form-control"
                                >
                                    <option defaultValue value="">
                                        {props.updateForm.condition}
                                    </option>
                                    {FactorsConditionsList}
                                </Field>
                                <ErrorMessage
                                    name="factorsCondition"
                                    component="div"
                                    className={classes.errorMessage}
                                ></ErrorMessage>
                            </Grid>

                            <Grid item style={{ width: "25%" }}>
                                <div className={classes.inputLabel}>What If Analysis </div>

                                <Field
                                    value={props.updateForm.whatIf}
                                    as="select"
                                    name="whatIfAnalysis"
                                    className="form-control"
                                >
                                    <option disabled value="">
                                        What If Analysis Options
                                    </option>
                                    <option data-id={"True"} value="True">
                                        Yes
                                    </option>
                                    <option data-id={"False"} value="False">
                                        No
                                    </option>

                                </Field>
                                <ErrorMessage
                                    name="whatIfAnalysis"
                                    component="div"
                                    className={classes.errorMessage}
                                ></ErrorMessage>
                            </Grid>
                            {/* <Grid
                                item
                                style={{
                                    width: "25%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "2%",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    className={classes.createEntitiesActionButtons}
                                    onClick={props.onHideCreatFormHandler}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    className={classes.createEntitiesActionButtons}
                                    style={{ background: Colors.deloitte_green, color: "white" }}
                                >
                                    Save
                                </Button>
                            </Grid> */}
                             <Grid item style={{ width: "100%", display: 'flex', justifyContent: 'start', marginTop: '2%' }}>
                                <Button variant="outlined" className={clsx(classes.createEntitiesActionButtons, classes.cancelButton, 'mx-2')} onClick={props.onHideCreatFormHandler}>Cancel</Button>
                                <Button variant="contained" type="submit" className={clsx(classes.createEntitiesActionButtons, classes.addButton, 'mx-2')} style={{ background: Colors.deloitte_green, color: 'white' }}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>


        </div>
    )
}

export default UpdateParameter;
