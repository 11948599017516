import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AccessManagementTabs from '../../components/AccessManagementTabs/AccessManagementTabs'

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
 
}));

const AccessManagement = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AccessManagementTabs />
    </div>
  );
};

export default AccessManagement;
