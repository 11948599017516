import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import API from '../../varaibles/baseApi';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Paper,
    Button,
    IconButton,
} from "@material-ui/core";
import { MoreHoriz, SubscriptionsOutlined } from "@material-ui/icons";

import AlertDialog from '../../components/AlertDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
import Loader from '../../components/Loader';

function createData(
    ID,
    Group,
    RolesList,
    UsersList,
) {
    return {
        ID,
        Group,
        RolesList,
        UsersList,
    };
}


const headCells = [
    {
        id: "ID",
        numeric: false,
        disablePadding: true,
        label: "ID",
    },
    {
        id: "GroupName",
        numeric: true,
        disablePadding: false,
        label: "Group",
    },
    {
        id: "empty1",
        numeric: true,
        disablePadding: false,
        label: "",
    },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHeaderStyle}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding="default"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
        '& tr:nth-child(even)': {
            backgroundColor: '#fafafa',
        },
        '& tr:hover': {
            backgroundColor: '#fafafa',
        }
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    tableHeaderStyle: {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        background: '#fafafa'
    },
    TablePaginationStyle: {
        "& p:first-of-type": {
            display: "none",
        },
        "& .MuiTablePagination-selectRoot": {
            display: "none",
        },
    },
    sectionHeadTitle: {
        textAlign: 'left',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: '1%',
        display: 'inline-block'
    },
    sectionButton: {
        width: 'auto',
        color: "#005074",
        borderColor: "#005074",
        textTransform: "none",
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        padding: '0px 24px',
        marginLeft: '2%'
    },
    sectionHeader: {
        textAlign: 'left',
        marginBottom: '1%'
    },
    buttonFilledGreen: {
        lineHeight: 1.43,
        width: "100%",
        padding: "7px",
        color: "white",
        background: "#268e73",
        textTransform: "none",
    },
}));

const AccessGroupView = (props) => {
    const classes = useStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("ID");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentGroupsNum, setCurrentGroupsNum] = useState(15);
    const [groupsCount, setGroupsCount] = useState(0);
    const [rows, setRows] = useState([]);

    const [alertMessage, setAlertMessage] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const [confirmMessage, setConfirmMessage] = useState("");
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [removeSuccess, setRemoveItemSuccess] = useState();

    useEffect(() => {
        axios
            .get(
                "" + API.baseURL + "AccessGroup?index=" +
                1 +
                "&last=" +
                10 +
                ""
            )
            .then((response) => {
                let groupList = response.data.map((Group) =>
                    createData(
                        Group.id,
                        Group.group_Name,
                        Group.adminRoles,
                        Group.adminUsers,
                    )
                );
                setRows(groupList);

            });
        getGroupsCount();


    }, []);
    const getGroupsCount = () => {
        axios
            .get("" + API.baseURL + "AccessGroup/GroupsCount")
            .then((response) => {
                setGroupsCount(response.data);
            });
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";

        axios
            .get(
                "" + API.baseURL + "AccessGroup?SortBy=" +
                property +
                "&SortType=" +
                order +
                ""
            )
            .then((response) => {
                let sortedList = response.data.map((Group) =>
                    createData(
                        Group.id,
                        Group.group_Name,
                        Group.adminRoles,
                        Group.adminUsers,
                    )
                );
                setRows(sortedList);
                setCurrentGroupsNum(15);
                setPage(0);
            });
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setCurrentGroupsNum((prevState) => prevState + 5);
            axios
                .get(
                    "" + API.baseURL + "AccessGroup?index=" +
                    (currentGroupsNum - 4) +
                    "&last=" +
                    currentGroupsNum +
                    ""
                )
                .then((response) => {
                    let groupsAppendList = response.data.map((Group) =>
                        createData(
                            Group.id,
                            Group.group_Name,
                            Group.adminRoles,
                            Group.adminUsers,
                        )
                    );
                    console.log(rows);
                    let fullUpdatedList = rows.concat(groupsAppendList);
                    setRows(fullUpdatedList);
                    console.log(rows);
                });
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handelAlertView = () => {
        setAlertOpen(!alertOpen);
    }

    const handelAlertClose = () => {
        setAlertOpen(!alertOpen);
    }

    const handelDisagreeConfirm = () => {
        setConfirmOpen(!confirmOpen);
    }


    const deleteGroup = (groupID) => {
        setConfirmOpen(true);
        setConfirmMessage("Do you really want to delete this group?");

        const itemsDeletedList = () => {
            axios
                .delete(
                    `${API.baseURL}AccessGroup/${groupID}`
                )
                .then(function (response) {
                    let updatedGroupsList = rows.filter(item => item.ID !== groupID);
                    setRows(updatedGroupsList);
                    setConfirmOpen(false);
                    setGroupsCount(groupsCount - 1);
                }).catch(function (error) {
                    // console.log(error);
                    //  alert("This Supplier can not be deleted because it is exist in brand or product");
                    setAlertOpen(true);
                    setAlertMessage(error.request.response);
                    setConfirmOpen(!confirmOpen);

                });
        }
        setRemoveItemSuccess(() => itemsDeletedList);
    }

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    //
    return (
        <div className={classes.root}>

            <Loader showLoader={showLoader} />
            <AlertDialog handelAlertView={handelAlertView} handelAlertClose={handelAlertClose} alertOpen={alertOpen} alertMessage={alertMessage} />
            <ConfirmDialog handelDisagreeConfirm={handelDisagreeConfirm} handelAgreeConfirm={removeSuccess} confirmOpen={confirmOpen} confirmMessage={confirmMessage} />

            <div className={classes.sectionHeader}>
                <div className={classes.sectionHeadTitle}>
                    Access Management Groups
                </div>
                <Button
                    variant="outlined"
                    className={classes.sectionButton}
                    onClick={() => props.handelSelectedView('NewGroup')}
                >
                    New Group
                </Button>
            </div>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.ID}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="default"
                                            >
                                                {row.ID}
                                            </TableCell>
                                            <TableCell>{row.Group}</TableCell>
                                            <TableCell style={{ width: '16%' }}>
                                                <div style={{ float: 'right' }}>

                                                    <IconButton
                                                        onClick={() => { }}
                                                    >
                                                        <EditIcon style={{ color: "#005074" }} onClick={() =>  props.handelSelectedView('UpdateGroup', row.RolesList, row.UsersList, row.ID , row.Group)} />

                                                    </IconButton>

                                                    <IconButton
                                                        onClick={() => { }}
                                                    >
                                                        <DeleteIcon style={{ color: "#005074" }} onClick={() => deleteGroup(row.ID)} />

                                                    </IconButton>
                                                </div>

                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={3} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={groupsCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    className={classes.TablePaginationStyle}
                />
            </Paper>
        </div>
    );
};

export default AccessGroupView;
