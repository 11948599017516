import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button, IconButton, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { BackupOutlined, TramOutlined } from "@material-ui/icons";
import TextInput from "../input/input";
import axios from 'axios';
import API from '../../varaibles/baseApi';
import AlertDialog from '../AlertDialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DateFnsUtils from '@date-io/date-fns';

import Colors from '../../varaibles/colors'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: "2%",
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        boxShadow: 'none',
    },
    gridContainer: {
        justifyContent: "flex-end",
        width: '100%',
        borderRadius: '15px',
        padding: '15px',
        background: '#f4f6f9'
    },
    select: {
        border: "none",
        outline: "none",
        width: "100%",
    },
    button: {
        lineHeight: 1.43,
        width: "100%",
        padding: "7px",
        color: "#005074",
        borderColor: "#005074",
        textTransform: "none",
        fontWeight: 'bold'
    },
    buttonFilled: {
        lineHeight: 1.43,
        width: "100%",
        padding: "7px",
        color: "white",
        background: "#005074",
        // borderColor: "#005074",
        textTransform: "none",
    },
    errorMessage: {
        textAlign: "left",
        marginLeft: "4px",
        color: "red",
        fontSize: "12px",
    },
    textField: {
        underline: {
            "&&&:before": {
                borderBottom: "none",
            },
            "&&:after": {
                borderBottom: "none"
            }
        }
    },
    inputLabel: {
        marginBottom: '2%',
        fontWeight: 700,
        textAlign: 'left'
    },
    outlinedInputStyle: { background: 'white', width: '100%', height: '35px' },
    selectInputPaper: {
        border: '1px solid rgba(0, 0, 0, 0.23) !important',
        height: '35px',
        width: '99%'
    },
    createEntitiesActionButtons: { textTransform: 'none', width: '45%', fontWeight: 700 }
}));




function UpdateNode(props) {

    /***************     INPUTS ERROR HANDLER      **************** */
    const [showLoader, setShowLoader] = useState(false);

    const [nameError, setNameError] = useState(false);
    const [codeError, setCodeError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [entityError, setEntityError] = useState(false);
    const [urlError, setUrlError] = useState(false);

    const [entityList, setEntityList] = useState(null);

    /***************     INPUTS VALUES HANDLER      **************** */
    const [nameInputVal, setNameInputVal] = useState('');
    const [codeInputVal, setCodeInputVal] = useState('');
    const [descriptionInputVal, setDescriptionInputVal] = useState('');
    const [entityInputVal, setEntityInputVal] = useState("Entity");
    const [urlInputVal, setUrlInputVal] = useState('');

    /***************     SELECTS INPUT OPTIONS HANDLER      **************** */

    const [alertMessage, setAlertMessage] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);

    useEffect(() => {
        const updateFormData = props.updateForm;
        axios
            .get("" + API.baseURL + "Enitities/LockupEntities")
            .then((response) => {
                let entitiesList = response.data.map((entity) => (
                    <option value={entity.id} data-id={entity.name} key={entity.id}>{entity.name}</option>
                ));
                setEntityList(entitiesList);
            });

        setNameInputVal(updateFormData.Name);
        setCodeInputVal(updateFormData.Code);
        setDescriptionInputVal(updateFormData.Description);
        setEntityInputVal(updateFormData.EnitityId);
        setUrlInputVal(updateFormData.Url);
    }, []);

    const onChangeInputHandler = (event, inputType) => {
        if (inputType == 'entity') {
            var index = event.target.selectedIndex;
            var optionElement = event.target.childNodes[index]
            var option = optionElement.getAttribute('data-id');
        }

        switch (inputType) {

            case 'name':
                setNameInputVal(event.target.value)
                if (event.target.value) {
                    setNameError(false)
                } else {
                    setNameError(true)
                }
                break;

            case 'code':
                setCodeInputVal(event.target.value)
                if (event.target.value) {
                    setCodeError(false)
                } else {
                    setCodeError(true)
                }
                break;

            case 'description':
                setDescriptionInputVal(event.target.value)
                if (event.target.value) {
                    setDescriptionError(false)
                } else {
                    setDescriptionError(true)
                }
                break;


            case 'entity':
                setEntityInputVal(event.target.value);
                setEntityError(false)
                break;

            case 'url':
                setUrlInputVal(event.target.value)
                if (event.target.value) {
                    setUrlError(false)
                } else {
                    setUrlError(true)
                }
                break;
        }
    }

    const handelAlertView = () => {
        setAlertOpen(!alertOpen);
    }


    const handelAlertClose = () => {
        setAlertOpen(!alertOpen);
    }

    function addProductHandler() {

        if (nameInputVal === "") { setNameError(true); } else { setNameError(false) }
        if (codeInputVal === "") { setCodeError(true); } else { setCodeError(false) }
        if (descriptionInputVal === "") { setDescriptionError(true); } else { setDescriptionError(false) }
        if (entityInputVal === "Entity") { setEntityError(true); } else { setEntityError(false) }
        if (urlInputVal === "") { setUrlError(true); } else { setUrlError(false) }


        if (entityInputVal != "Entity" && nameInputVal && codeInputVal && descriptionInputVal && urlInputVal) {
            setShowLoader(true);
            setNameInputVal('');
            setCodeInputVal('');
            setDescriptionInputVal('');
            setEntityInputVal('Entity');
            setUrlInputVal('');

            axios({
                method: 'put',
                url: `${API.baseURL}Nodes/${Number(props.updateForm.Id)}`,

                data: {
                    Id:Number(props.updateForm.Id),
                    Name: String(nameInputVal),
                    Code: String(codeInputVal),
                    Description: String(descriptionInputVal),
                    EnitityId: entityInputVal,
                    Url: String(urlInputVal),
                }

            }).then(function (response) {
                console.log(response);
                setShowLoader(false);
                setAlertOpen(true);
                setAlertMessage("Node Updated Successfully");
                props.onEditDoneHandler();
                props.loadAllNodes();
                props.onEditDoneHandler();
            }).catch(function (error) {
                setShowLoader(false);
                console.log(error);
            });

        }
    }

    //export default function FullWidthGrid() {
    const classes = useStyles();

    var nameErrorPaperStyle = (nameError ? { border: '1px solid red' } : { border: 'none' });
    var codeErrorPaperStyle = (codeError ? { border: '1px solid red' } : { border: 'none' });


    return (
        <div className={classes.root}>

            <AlertDialog handelAlertView={handelAlertView} handelAlertClose={handelAlertClose} alertOpen={alertOpen} alertMessage={alertMessage} />

            <Grid className={classes.gridContainer} container spacing={1}>
                <Grid item style={{ width: "20%" }}>
                    <FormControl className={clsx(classes.textField)} variant="outlined">
                        <div className={classes.inputLabel}>
                            Node Name
                        </div>
                        <OutlinedInput
                            value={nameInputVal}
                            className={classes.outlinedInputStyle}
                            id="outlined-adornment-weight"
                            onChange={(event) => onChangeInputHandler(event, 'name')}
                            aria-describedby="outlined-weight-helper-text"
                            labelWidth={0}
                        />
                    </FormControl>
                    {nameError ? (
                        <div className={classes.errorMessage}>
                            Node Name is required
                        </div>
                    ) : (
                            ""
                        )}
                </Grid>

                <Grid item style={{ width: "20%" }}>
                    <FormControl className={clsx(classes.textField)} variant="outlined">
                        <div className={classes.inputLabel}>
                            Code
                        </div>
                        <OutlinedInput
                            value={codeInputVal}
                            className={classes.outlinedInputStyle}
                            id="outlined-adornment-weight"
                            onChange={(event) => onChangeInputHandler(event, 'code')}
                            aria-describedby="outlined-weight-helper-text"
                            labelWidth={0}
                        />
                    </FormControl>
                    {codeError ? (
                        <div className={classes.errorMessage}>
                            Code is required
                        </div>
                    ) : (
                            ""
                        )}
                </Grid>

                <Grid item style={{ width: "20%" }}>
                    <FormControl className={clsx(classes.textField)} variant="outlined">
                        <div className={classes.inputLabel}>
                            Description
                        </div>
                        <OutlinedInput
                            value={descriptionInputVal}
                            className={classes.outlinedInputStyle}
                            id="outlined-adornment-weight"
                            onChange={(event) => onChangeInputHandler(event, 'description')}
                            aria-describedby="outlined-weight-helper-text"
                            labelWidth={0}
                        />
                    </FormControl>
                    {descriptionError ? (
                        <div className={classes.errorMessage}>
                            Description is required
                        </div>
                    ) : (
                            ""
                        )}
                </Grid>

                <Grid item style={{ width: "20%" }}>
                    <div className={classes.inputLabel}>
                        Entity
                    </div>
                    <Paper className={clsx(classes.paper, classes.selectInputPaper)}>

                        <select value={entityInputVal} className={classes.select} onChange={(event) => onChangeInputHandler(event, 'entity')}>
                            <option disabled value="Entity">
                                Entity Options
                            </option>
                            {entityList}
                        </select>
                    </Paper>
                    {entityError ? (
                        <div className={classes.errorMessage}>
                            Entity is required
                        </div>
                    ) : (
                            ""
                        )}
                </Grid>

                <Grid item style={{ width: "20%" }}>
                    <FormControl className={clsx(classes.textField)} variant="outlined">
                        <div className={classes.inputLabel}>
                            URL
                        </div>
                        <OutlinedInput
                            value={urlInputVal}
                            className={classes.outlinedInputStyle}
                            id="outlined-adornment-weight"
                            onChange={(event) => onChangeInputHandler(event, 'url')}
                            aria-describedby="outlined-weight-helper-text"
                            labelWidth={0}
                        />
                    </FormControl>
                    {urlError ? (
                        <div className={classes.errorMessage}>
                            URL is required
                        </div>
                    ) : (
                            ""
                        )}
                </Grid>

                <Grid item style={{ width: "25%", display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                    <Button variant="contained" className={classes.createEntitiesActionButtons} onClick={ props.onEditDoneHandler}>Cancel</Button>
                    <Button variant="contained" onClick={() => addProductHandler()} className={classes.createEntitiesActionButtons} style={{ background: Colors.deloitte_green, color: 'white' }}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
export default UpdateNode;