import React from "react";
import { Button as MaterialButton } from "@material-ui/core";
import clsx from 'clsx';




const Button = ({ children, className, classes }) => {

    return (
        <MaterialButton className={clsx(className, classes.button)}>
            {children}
        </MaterialButton>
    )
}


export default React.memo(Button);