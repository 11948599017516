import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Colors from '../varaibles/colors'
const useStyles = makeStyles((theme) => ({
    confirmContainer23456: {
        // width: '51%',
        backgroundColor: 'red'
        // height: '40%',
    },
    confirmButtons: {
        lineHeight: 1.43,
        width: '20%',
        color: Colors.deloitte_green,
        borderColor: Colors.deloitte_green,
        textTransform: "none",
    },
}))

const ConfirmDialog = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div style={{width:'200px'}}>
            <Dialog
                fullScreen={fullScreen}
                open={props.confirmOpen}
                onClose={props.handelDisagreeConfirm}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle id="responsive-dialog-title">{props.confirmMessage}</DialogTitle>

                <DialogActions>
                    <Button onClick={props.handelAgreeConfirm}  className={classes.confirmButtons} variant="contained" style={{ background: Colors.deloitte_green, marginLeft: '1%', color: 'white' }}>
                        AGREE
                        </Button>
                    <Button onClick={props.handelDisagreeConfirm}  className={classes.confirmButtons} variant="outlined">
                        DISAGREE
                        </Button>
                </DialogActions>


            </Dialog>
        </div>
    );
}
export default ConfirmDialog;