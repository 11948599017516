import React from "react";
import { Search } from "../Search";
import clsx from 'clsx';

import Add from '../../assets/icons/add.svg';
import Export from '../../assets/icons/export.svg';
import Import from '../../assets/icons/import.svg'
import Delete from '../../assets/icons/delete_gray.svg';

const SectionHeader = ({ list, setList, loadAll, classes, onAddClick, onExportClick, onImportCLick, onDeleteClick, hideIcons }) => {
    return (
        <div className={classes.container}>
            <Search list={list} setList={setList} loadAll={loadAll} />
            <div className=""></div>
            <div className={classes.actionButtons}>
                {!hideIcons?.add && <img className={clsx(classes.addImg, 'mx-2')} src={Add} onClick={onAddClick} title="add" />}
                {!hideIcons?.export && <img className={clsx(classes.exportImg, 'mx-2')} src={Export} onClick={onExportClick} title="export" />}
                {!hideIcons?.import && <img className={clsx(classes.importImg, 'mx-2')} src={Import} onClick={onImportCLick} title="import" />}
                {!hideIcons?.delete && <img className={clsx(classes.deleteImg, 'mx-2')} src={Delete} onClick={onDeleteClick} title="delete" />}
            </div>
        </div>
    )
}

export default React.memo(SectionHeader);