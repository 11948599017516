import React from 'react';
import './App.css';
import Layout from '../src/components/Layout'
import { makeStyles } from '@material-ui/core/styles';

import { BrowserRouter } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function App() {
  const classes = useStyles();
  
  return (
    <div className="App">
      <BrowserRouter>
        <Layout className={classes.container} />
      </BrowserRouter>
    </div>
  );
}

export default App;
