import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Paper, Button, IconButton, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { BackupOutlined, TramOutlined } from "@material-ui/icons";
import TextInput from "../input/input";
import axios from "axios";
import API from "../../varaibles/baseApi";
import AlertDialog from "../AlertDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";

import Colors from "../../varaibles/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "2%",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  },
  gridContainer: {
    // justifyContent: "flex-end",
    width: "100%",
    borderRadius: "15px",
    padding: "15px",
    background: "#fff",
  },
  select: {
    border: "none",
    outline: "none",
    width: "100%",
  },
  button: {
    lineHeight: 1.43,
    width: "100%",
    padding: "7px",
    color: "#005074",
    borderColor: "#005074",
    textTransform: "none",
    fontWeight: "bold",
  },
  buttonFilled: {
    lineHeight: 1.43,
    width: "100%",
    padding: "7px",
    color: "white",
    background: "#005074",
    // borderColor: "#005074",
    textTransform: "none",
  },
  errorMessage: {
    textAlign: "left",
    marginLeft: "4px",
    color: "red",
    fontSize: "12px",
  },
  textField: {
    width: '100%',
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
  },
  inputLabel: {
    marginBottom: "2%",
    // fontWeight: 700,
    textAlign: "left",
  },
  outlinedInputStyle: { background: "white", width: "100%", height: '41px', borderRadius: '10px' },
  selectInputPaper: {
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
    height: "41px",
    width: "99%",
    borderRadius: '10px'
  },
  createEntitiesActionButtons: { textTransform: 'none', width: '10%', height: '41px', borderRadius: '10px' },
  addButton: {
    backgroundColor: Colors.deloitte_green,
    color: Colors.deloitte_green,
  },
  cancelButton: {
    backgroundColor: '#fff',
    color: Colors.deloitte_green,
    borderColor: Colors.deloitte_green
  }

}));

function CreateScoreCardDetails(props) {
  /***************     INPUTS ERROR HANDLER      **************** */
  const [showLoader, setShowLoader] = useState(false);

  const [scoreCardError, setScoreCardError] = useState(false);
  const [parameterError, setParameterError] = useState(false);
  const [factorScoreError, setFactorScoreError] = useState(false);
  const [statusIdError, setStatusIdError] = useState(false);
  const [childScoreCardError, setChildScoreCardError] = useState(false);

  const [scoreCardsList, setScoreCardsList] = useState(null);
  const [scoreCards, setScoreCards] = useState([]);
  const [statusIdList, setStatusIdList] = useState(null);
  const [parametersList, setParametersList] = useState(null);

  /***************     INPUTS VALUES HANDLER      **************** */
  const [scoreCardInputVal, setScoreCardInputVal] = useState(
    "ScoreCards Options"
  );
  const [refresh, setRefresh] = useState(false);
  const [parameterInputVal, setParameterInputVal] = useState(
    "Parameters Options"
  );
  const [childScoreCard, setChildScoreCard] = useState('');
  const [factorScoreInputVal, setFactorScoreInputVal] = useState("");
  const [statusIdInputVal, setStatusIdInputVal] = useState("StatusId Options");

  /***************     SELECTS INPUT OPTIONS HANDLER      **************** */

  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    axios
      .get("" + API.baseURL + "ScoreCards/LockupScoreCards")
      .then((response) => {
        setScoreCards(response.data);
        let scoreCardList = response.data.map((scoreCard) => (
          <option
            value={scoreCard.id}
            data-id={scoreCard.cardName}
            key={scoreCard.id}
          >
            {scoreCard.cardName}
          </option>
        ));
        setScoreCardsList(scoreCardList);
      });

    axios.get("" + API.baseURL + "status").then((response) => {
      let statusList = response.data.map((status) => (
        <option value={status.id} data-id={status.label} key={status.id}>
          {status.label}
        </option>
      ));
      setStatusIdList(statusList);
    });

    axios
      .get("" + API.baseURL + "Parameters/LockupParameter")
      .then((response) => {
        let parametersList = response.data.map((parameter) => (
          <option
            value={parameter.id}
            data-id={parameter.parameterName}
            key={parameter.id}
          >
            {parameter.parameterName}
          </option>
        ));
        setParametersList(parametersList);
      });
  }, []);

  const onChangeInputHandler = (event, inputType) => {
    if (inputType == "scoreCard" || inputType == "statusId") {
      var index = event.target.selectedIndex;
      var optionElement = event.target.childNodes[index];
      var option = optionElement.getAttribute("data-id");
    }

    switch (inputType) {
      case "scoreCard":
        setScoreCardInputVal(event.target.value);
        if (event.target.value) {
          setScoreCardError(false);
        } else {
          setScoreCardError(true);
        }
        break;

      case "parameter":
        setParameterInputVal(event.target.value);
        if (event.target.value) {
          setParameterError(false);
        } else {
          setParameterError(true);
        }
        break;

      case "factorScore":
        setFactorScoreInputVal(event.target.value);
        if (event.target.value) {
          setFactorScoreError(false);
        } else {
          setFactorScoreError(true);
        }
        break;

      case "statusId":
        setStatusIdInputVal(event.target.value);
        if (event.target.value) {
          setStatusIdError(false);
        } else {
          setStatusIdError(true);
        }
        break;
      case "childScoreCard":
        setChildScoreCard(event.target.value);
        if (event.target.value) {
          setChildScoreCardError(false);
        } else {
          setChildScoreCardError(true);
        }
        break;
    }
  };

  const handelAlertView = () => {
    setAlertOpen(!alertOpen);
  };

  const handelAlertClose = () => {
    setAlertOpen(!alertOpen);
  };

  function addProductHandler() {
    if (scoreCardInputVal === "ScoreCards Options") {
      setScoreCardError(true);
    } else {
      setScoreCardError(false);
    }
    if (parameterInputVal === "Parameters Options") {
      setParameterError(true);
    } else {
      setParameterError(false);
    }
    if (factorScoreInputVal === "") {
      setFactorScoreError(true);
    } else {
      setFactorScoreError(false);
    }
    if (statusIdInputVal === "StatusId Options") {
      setStatusIdError(true);
    } else {
      setStatusIdError(false);
    }

    if (
      scoreCardInputVal != "ScoreCards Options" &&
      parameterInputVal != "Parameters Options" &&
      factorScoreInputVal &&
      statusIdInputVal != "StatusId Options"
    ) {
      setShowLoader(true);
      setScoreCardInputVal("ScoreCards Options");
      setParameterInputVal("Parameters Options");
      setFactorScoreInputVal("");
      setStatusIdInputVal("StatusId Options");

      axios({
        method: "post",
        url: "" + API.baseURL + "CardDetails/AddCardDetails",
        data: {
          scoreCardId: scoreCardInputVal,
          parameterId: parameterInputVal,
          weight: factorScoreInputVal,
          statusId: statusIdInputVal,
          childCardId: childScoreCard,
        },
      })
        .then(function (response) {
          console.log(response);
          setShowLoader(false);
          setAlertOpen(true);
          setAlertMessage("Risk Profile Details Added Successfully");
          props.loadAllScoreCardDetails();
        })
        .catch(function (error) {
          setShowLoader(false);
          console.log(error);
        });
    }
  }
  const paramOrChild = () => {
    let x = scoreCards.filter(
      (item) => item.id == scoreCardInputVal && item.isParent
    )[0];
    if (x) {
      return (
        <>
          <div className={classes.inputLabel}>Child score card</div>
          <Paper className={clsx(classes.paper, classes.selectInputPaper)}>
            <select
              value={childScoreCard}
              className={classes.select}
              onChange={(event) =>
                onChangeInputHandler(event, "childScoreCard")
              }
            >
              <option disabled value="Score Card Options">
                Score Card Options
              </option>
              {scoreCards
                .filter((item) => item.id != scoreCardInputVal)
                .map((scoreCard) => (
                  <option
                    value={scoreCard.id}
                    data-id={scoreCard.cardName}
                    key={scoreCard.id}
                  >
                    {scoreCard.cardName}
                  </option>
                ))}
            </select>
          </Paper>
          {childScoreCardError ? (
            <div className={classes.errorMessage}>Child card is required</div>
          ) : (
            ""
          )}
        </>
      );
    } else {
      return (
        <>
          <div className={classes.inputLabel}>Parameter</div>
          <Paper className={clsx(classes.paper, classes.selectInputPaper)}>
            <select
              value={parameterInputVal}
              className={classes.select}
              onChange={(event) => onChangeInputHandler(event, "parameter")}
            >
              <option disabled value="Parameters Options">
                Parameters Options
              </option>
              {parametersList}
            </select>
          </Paper>
          {parameterError ? (
            <div className={classes.errorMessage}>Parameter is required</div>
          ) : (
            ""
          )}
        </>
      );
    }
  };

  //export default function FullWidthGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AlertDialog
        handelAlertView={handelAlertView}
        handelAlertClose={handelAlertClose}
        alertOpen={alertOpen}
        alertMessage={alertMessage}
      />

      <Grid className={classes.gridContainer} container spacing={1}>
        <Grid item style={{ width: "25%" }}>
          <div className={classes.inputLabel}>ScoreCard</div>
          <Paper className={clsx(classes.paper, classes.selectInputPaper)}>
            <select
              value={scoreCardInputVal}
              className={classes.select}
              onChange={(event) => onChangeInputHandler(event, "scoreCard")}
            >
              <option disabled value="ScoreCards Options">
                ScoreCards Options
              </option>
              {scoreCardsList}
            </select>
          </Paper>
          {scoreCardError ? (
            <div className={classes.errorMessage}>Score Card is required</div>
          ) : (
            ""
          )}
        </Grid>

        <Grid item style={{ width: "25%" }}>
          {paramOrChild()}
        </Grid>

        <Grid item style={{ width: "25%" }}>
          <FormControl className={clsx(classes.textField)} variant="outlined">
            <div className={classes.inputLabel}>Factor Score</div>
            <OutlinedInput
              value={factorScoreInputVal}
              className={classes.outlinedInputStyle}
              id="outlined-adornment-weight"
              onChange={(event) => onChangeInputHandler(event, "factorScore")}
              aria-describedby="outlined-weight-helper-text"
              labelWidth={0}
            />
          </FormControl>
          {factorScoreError ? (
            <div className={classes.errorMessage}>Factor Score is required</div>
          ) : (
            ""
          )}
        </Grid>

        <Grid item style={{ width: "25%" }}>
          <div className={classes.inputLabel}>StatusId</div>
          <Paper className={clsx(classes.paper, classes.selectInputPaper)}>
            <select
              value={statusIdInputVal}
              className={classes.select}
              onChange={(event) => onChangeInputHandler(event, "statusId")}
            >
              <option disabled value="StatusId Options">
                StatusId Options
              </option>
              {statusIdList}
            </select>
          </Paper>
          {statusIdError ? (
            <div className={classes.errorMessage}>StatusId is required</div>
          ) : (
            ""
          )}
        </Grid>

        {/* <Grid
          item
          style={{
            width: "25%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "2%",
          }}
        >
          <Button
            variant="contained"
            className={classes.createEntitiesActionButtons}
            onClick={props.onHideCreatFormHandler}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => addProductHandler()}
            className={classes.createEntitiesActionButtons}
            style={{ background: Colors.deloitte_green, color: "white" }}
          >
            Save
          </Button>
        </Grid> */}
        <Grid item style={{ width: "100%", display: 'flex', justifyContent: 'start', marginTop: '2%' }}>
          <Button variant="outlined" className={clsx(classes.createEntitiesActionButtons, classes.cancelButton, 'mx-2')} onClick={props.onHideCreatFormHandler}>Cancel</Button>
          <Button variant="contained" onClick={() => addProductHandler()} className={clsx(classes.createEntitiesActionButtons, classes.addButton, 'mx-2')} style={{ background: Colors.deloitte_green, color: 'white' }}>
            Add
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
export default CreateScoreCardDetails;
