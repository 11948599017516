import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader";
import { makeStyles, useTheme, fade } from "@material-ui/core/styles";
import Colors from "../../varaibles/colors";
import PropTypes from "prop-types";
import { MoreHoriz, Search, MoreVert } from "@material-ui/icons";
import axios from 'axios';
import InputBase from "@material-ui/core/InputBase";
import API from '../../varaibles/baseApi';
import Menu from "@material-ui/core/Menu";
import ConfirmDialog from "../../components/ConfirmDialog";
import MenuItem from "@material-ui/core/MenuItem";
import UpdateManagedListDetails from "../../components/createManagedListDetails/UpdateManagedListDetails"
import CreateManagedListDetails from "../../components/createManagedListDetails/CreateManagedListDetails"
import {
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TableSortLabel,
  Checkbox,
} from "@material-ui/core";

import { SectionHeader } from '../../components/SectionHeader';

import Delete from '../../assets/icons/delete_red.svg';
import Edit from '../../assets/icons/edit.svg';
import clsx from 'clsx'

const headCells = [
  {
    id: "Id",
    numeric: false,
    disablePadding: true,
    label: "Id",
  },
  {
    id: "itemName",
    numeric: true,
    disablePadding: false,
    label: "Value",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  baseRootPageStyle: {
    // background: "white",
    // padding: "15px",
    // borderRadius: "15px",
    // boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.04)",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    borderRadius: 0,
  },
  table: {
    minWidth: 750,
    "& tr:nth-child(even)": {
      backgroundColor: "#fafafa",
    },
    "& tr:hover": {
      backgroundColor: "#fafafa",
    },
    "& tbody .MuiTableRow-root.Mui-selected": {
      backgroundColor: Colors.lightYellow,
    },
    "& tbody .MuiCheckbox-colorSecondary.Mui-checked": {
      color: Colors.yellow,
    },
    padding: '20px'
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableHeaderStyle: {
    // borderBottom: "1px solid rgba(224, 224, 224, 1)",
    background: "#fff",
  },
  TablePaginationStyle: {
    "& p:first-of-type": {
      display: "none",
    },
    "& .MuiTablePagination-selectRoot": {
      display: "none",
    },
  },
  sizeCellContainer: {
    textAlign: "center",
    background: "#8498c9",
    color: "white",
    padding: "4px",
    width: "70%",
    borderRadius: "4px",
  },
  tableContainer: {
    maxHeight: 260,
  },
  sectionTitle: {
    textAlign: "left",
    fontSize: "20px",
    fontWeight: "bold",
  },
  removeBtn: {
    background: "#b40100",
    textTransform: "none",
    color: "white",
  },
  transactionActionsContainer: {
    textAlign: "right",
  },
  button: {
    lineHeight: 1.43,
    width: "20%",
    color: "#005074",
    borderColor: "#005074",
    textTransform: "none",
    fontWeight: "bold",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
    border: "1px solid darkgrey",
    borderRadius: "12px",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: Colors.black,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  menuIconStyle: {
    width: "16%",
    filter: 'brightness(0)'
  },
  tableHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2%",
  },
  actions: {
    cursor: 'pointer'
  }
}));


function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeaderStyle}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="default"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: "bold" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const ManagedListDetails = (props) => {
  const [order, setOrder] = useState("asc");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [orderBy, setOrderBy] = useState("Id");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = React.useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const [selectedRow, setSelectedRow] = useState([]);
  const [manageListDetails, setManageListDetails] = useState([]);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [removeSuccess, setRemoveItemSuccess] = useState();
  const [multipleDelete, setMultipleDelete] = useState(false);
  const [moreRowEl, setMoreRowEl] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(false);
  const [showCreateDetailsForm, setShowCreateDetailsForm] = useState(false);
  const [updateForm, setUpdateForm] = useState(null);





  const onDeleteTableRowHandler = (rowID) => {
    console.log(rowID);
    handleRowMenuClose();
    setConfirmOpen(true);
    setConfirmMessage("Do you really want to delete this item?");

    const itemsDeletedList = () => {
      axios
        .delete(`${API.baseURL}ListDetails/${rowID}`)
        .then(function (response) {
          let List = setManageListDetails.filter((row) => row.id !== rowID);
          setManageListDetails(List);
          setAlertOpen(true);
          setAlertMessage("Deleted Successfully");
          setConfirmOpen(false);
        })
        .catch(function (error) {
          setAlertOpen(true);
          setAlertMessage(error.request.response);
          setConfirmOpen(false);
        });
    };
    setRemoveItemSuccess(() => itemsDeletedList);
  };


  const handleRowMenuClose = () => {
    setMoreRowEl(null);
  };

  const onEditTableRowHandler = (rowData) => {
    handleRowMenuClose();
    setShowCreateDetailsForm(false);
    setUpdateForm(rowData);
  };

  const handelDisagreeConfirm = () => {
    setConfirmOpen(!confirmOpen);
  };


  const onMenuMultipleDelete = () => {
    handleMenuClose();
    setConfirmOpen(true);
    setConfirmMessage("Do you really want to delete these items?");

    const itemsDeletedList = () => {
      axios({
        method: "delete",
        url: "" + API.baseURL + "ListDetails/MultipleDeleteManagedListsDetails",
        data: selectedRow,
      })
        .then(function (response) {
          setAlertOpen(true);
          setAlertMessage("Deleted Successfully");
          setConfirmOpen(false);
          loadManagedListDetails();
        })
        .catch(function (error) {
          setAlertOpen(true);
          setAlertMessage(error.request.response);
          setConfirmOpen(false);
        });
    };
    setRemoveItemSuccess(() => itemsDeletedList);
  };


  const onChangeSearchHandler = (event) => {
    setSearchInputValue(event.target.value);
    console.log(event.target.value);
    if (event.target.value) {
      let filteredList = manageListDetails.filter((item) =>
        item.itemName.toLowerCase().includes(event.target.value.toLowerCase())
      );
      console.log(filteredList);
      setManageListDetails(filteredList);
    } else {
      loadManagedListDetails();
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function createManagedListDetailsData(id, itemName, managedListId) {
    return { id, itemName, managedListId };
  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    console.log(name);
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;


  const loadManagedListDetails = () => {
    if (props.selected.length > 1) {
      setManageListDetails([]);
    } else {
      axios
        .get("" + API.baseURL + "ListDetails/apiManagedListDetailsById?id=" + props.selected)
        .then((response) => {
          let ManageListDetails = response.data.map((entity) => (
            createManagedListDetailsData(
              entity.id,
              entity.itemName,
              entity.managedListId,

            )
          ));
          setManageListDetails(ManageListDetails);
        })
    }

    // axios
    // .get("" + API.baseURL + "ListDetails/apiManagedListDetailsById?id="+props.selected)
    // .then((response) => {
    //     let ManageListDetails = response.data.map((entity) => (
    //         createManagedListDetailsData(
    //             entity.id,
    //             entity.itemName,
    //             entity.managedListId,

    //         )
    //     ));
    //     setManageListDetails(ManageListDetails);
    // });

  }

  useEffect(() => {

    if (props.isAdd === true) {
      setShowCreateDetailsForm(true);
    }

    console.log("ShowCreate", showCreateDetailsForm);
    console.log("Propsssss", props.selected);
    loadManagedListDetails();
  }, [props]);



  const handleRowMenuClick = (event, rowData) => {
    setMoreRowEl(event.currentTarget);
    setSelectedRowData(rowData);
  };


  const handleTableRowClick = (event, ID) => {

    const selectedIndex = selectedRow.indexOf(ID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRow, ID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRow.slice(1));
    } else if (selectedIndex === selectedRow.length - 1) {
      newSelected = newSelected.concat(selectedRow.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRow.slice(0, selectedIndex),
        selectedRow.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length > 0) {
      setMultipleDelete(true);
    } else {
      setMultipleDelete(false);
    }
    setSelectedRow(newSelected);
    console.log("Row", selectedRow);
  };

  const onEditDoneHandler = (rowData) => {
    setUpdateForm(null);
  };

  const onHideCreatFormHandler = () => {
    setShowCreateDetailsForm(false);
  };


  const onMenuAddHandler = () => {
    handleMenuClose();
    setShowCreateDetailsForm(true);
    setUpdateForm(null);
  };

  return (

    <div className={classes.baseRootPageStyle} >
      <Loader showLoader={showLoader} />

      {/* <div className={classes.tableHeaderContainer}>

            <div className={classes.sectionTitle}>Managed List Details</div>


                    <div style={{ display: "flex", alignItems: "center" }}>
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <Search />
                      </div>
                      <InputBase
                        value={searchInputValue}
                        placeholder="Search"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{ "aria-label": "search" }}
                        onChange={onChangeSearchHandler}
                      />
                    </div>
                    <IconButton
                      onClick={handleMenuClick}
                      style={{ marginLeft: "10px", color: Colors.black }}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      component="span"
                    >
                      <MoreVert />
                    </IconButton>

                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                  
                      <MenuItem onClick={handleMenuClose}>
                        <img
                          className={classes.menuIconStyle}
                          src={require("../../assets/img/Export.png")}
                        />

                        <div style={{ marginLeft: "8px" }}>Export</div>
                      </MenuItem>
                      <MenuItem onClick={handleMenuClose}>
                        <img
                          className={classes.menuIconStyle}
                          src={require("../../assets/img/Import.png")}
                        />

                        <div style={{ marginLeft: "8px" }}>Import</div>
                      </MenuItem>
                      {selectedRow.length > 0 ? (
                        <MenuItem onClick={onMenuMultipleDelete}>
                          <img
                            className={classes.menuIconStyle}
                            src={require("../../assets/img/Delete-orange.png")}
                          />
                          <div style={{ marginLeft: "8px" }}>Delete</div>
                        </MenuItem>
                      ) : (
                        ""
                      )}
                    </Menu>
                    </div>

             </div> */}

      <SectionHeader onAddClick={onMenuAddHandler} list={manageListDetails} setList={setManageListDetails} loadAll={loadManagedListDetails} hideIcons={{
        add: true,
        delete: true
      }} />


      {showCreateDetailsForm ? (
        <CreateManagedListDetails SelectedItem={props.selected} loadManagedListDetails={loadManagedListDetails} setShowCreateDetailsForm={setShowCreateDetailsForm} onHideCreatFormHandler={onHideCreatFormHandler}></CreateManagedListDetails>

      ) : ("")}

      {updateForm ? (
        <UpdateManagedListDetails
          onEditDoneHandler={onEditDoneHandler}
          updateForm={updateForm}
          onHideCreatFormHandler={onHideCreatFormHandler}
          loadManagedListDetails={loadManagedListDetails}
        />

      ) : ("")}
      <ConfirmDialog
        handelDisagreeConfirm={handelDisagreeConfirm}
        handelAgreeConfirm={removeSuccess}
        confirmOpen={confirmOpen}
        confirmMessage={confirmMessage}
      />

      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            {manageListDetails.length > 0 ? (
              <TableBody>
                {stableSort(manageListDetails, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) =>
                              handleTableRowClick(event, row.id)
                            }
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>

                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="default"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell>{row.itemName}</TableCell>
                        <TableCell>
                          {/* <IconButton
                            onClick={(event) => handleRowMenuClick(event, row)}
                            style={{ marginLeft: "10px", color: Colors.black }}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            component="span"
                            disabled={multipleDelete}
                          >
                            <MoreHoriz style={{ color: Colors.black }} />
                          </IconButton> */}

                          {/* <Menu
                            id="simple-menu"
                            anchorEl={moreRowEl}
                            keepMounted
                            open={Boolean(moreRowEl)}
                            onClose={handleRowMenuClose}
                          >

                            <MenuItem
                              onClick={() =>
                                onEditTableRowHandler(selectedRowData)
                              }
                              style={{ justifyContent: "center" }}
                            >
                              <img
                                style={{ width: "20% !important" }}
                                className={classes.menuIconStyle}
                                src={require("../../assets/img/edit_3x2.png")}
                              />
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                onDeleteTableRowHandler(selectedRowData.id)
                              }
                              style={{ justifyContent: "center" }}
                            >
                              <img
                                className={classes.menuIconStyle}
                                style={{ width: "20% !important" }}
                                src={require("../../assets/img/Delete-orange.png")}
                              />
                            </MenuItem>
                          </Menu> */}
                          <div>
                            <img onClick={() => onEditTableRowHandler(row)} className={clsx("mx-2", classes.actions)} src={Edit} />
                            <img onClick={() => onDeleteTableRowHandler(row.Id)} className={clsx("mx-2", classes.actions)} src={Delete} />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: "center" }}>
                    {" "}
                    There Are No Parameters Yet, Try To Add Some
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>


    </div>

  )
}

export default ManagedListDetails;