import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button, IconButton, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {
  BackupOutlined,
  TramOutlined,
  ConfirmationNumber,
} from "@material-ui/icons";
import TextInput from "../input/input";
import axios from "axios";
import API from "../../varaibles/baseApi";
import AlertDialog from "../AlertDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import DateFnsUtils from "@date-io/date-fns";

import Colors from "../../varaibles/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "2%",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  },
  gridContainer: {
    // justifyContent: "flex-end",
    width: "100%",
    borderRadius: "15px",
    padding: "15px",
    background: "#fff",
  },
  select: {
    border: "none",
    outline: "none",
    width: "100%",
  },
  button: {
    lineHeight: 1.43,
    width: "100%",
    padding: "7px",
    color: Colors.accessible_green,
    borderColor: "#005074",
    textTransform: "none",
    fontWeight: "bold",
  },
  buttonFilled: {
    lineHeight: 1.43,
    width: "100%",
    padding: "7px",
    color: "white",
    background: "#005074",
    // borderColor: "#005074",
    textTransform: "none",
  },
  errorMessage: {
    textAlign: "left",
    marginLeft: "4px",
    color: "red",
    fontSize: "12px",
  },
  textField: {
    width: "100%",
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
  },
  inputLabel: {
    marginBottom: "2%",
    // fontWeight: 700,
    textAlign: "left",
  },
  outlinedInputStyle: { background: "white", width: "100%", height: '41px', borderRadius: '10px' },
  selectInputPaper: {
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
    height: "41px",
    width: "99%",
    borderRadius: '10px'
  },
  createEntitiesActionButtons: { textTransform: 'none', width: '10%', height: '41px', borderRadius: '10px' },
  addButton: {
    backgroundColor: Colors.deloitte_green,
    color: Colors.deloitte_green,
  },
  cancelButton: {
    backgroundColor: '#fff',
    color: Colors.deloitte_green,
    borderColor: Colors.deloitte_green
  }

}));

function CreateNode(props) {
  let reader;
  /***************     INPUTS ERROR HANDLER      **************** */
  const [showLoader, setShowLoader] = useState(false);

  const [nodeError, setNodeError] = useState(false);
  const [apiNameError, setApiNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const [entityList, setEntityList] = useState(null);
  const [selectedNode, setSelectedNode] = useState("");

  /***************     INPUTS VALUES HANDLER      **************** */
  const [nodeInputVal, setNodeInputVal] = useState("Select Node");
  const [apiNameInputVal, setApiNameInputVal] = useState("");
  const [descriptionInputVal, setDescriptionInputVal] = useState("");
  const [uploadedFileContent, setUploadedFileContent] = useState("");
  const [fileName, setFilename] = useState("");

  /***************     SELECTS INPUT OPTIONS HANDLER      **************** */
  const [methodsList, setMethodsList] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [apiDetailsId, setApiDetailsId] = useState(0);

  const getNodeMethods = (nodeId) => {
    axios
      .get(`${API.baseURL}Nodes/MssGetMethods?nodeId=${nodeId}`)
      .then((response) => {
        setMethodsList(response.data);
      });

    if (props.updateForm) {
      setNodeInputVal(props.updateForm.nodeId);
      setApiNameInputVal(props.updateForm.sourceAPIParam);
      setDescriptionInputVal(props.updateForm.fromAPIId);
      setApiDetailsId(props.updateForm.id);
      setIsUpdate(true);
    }
  };

  useEffect(() => {
    const id = props?.updateForm?.nodeId || props.SelectedItem
    axios
      .get("" + API.baseURL + "nodes/LockupNodesById?id=" + id)
      .then((response) => {
        setSelectedNode({ ...response.data, id });
        getNodeMethods(id);
      });
  }, [props]);

  const onChangeInputHandler = (event, inputType) => {
    if (inputType == "entity") {
      var index = event.target.selectedIndex;
      var optionElement = event.target.childNodes[index];
      var option = optionElement.getAttribute("data-id");
    }

    switch (inputType) {
      case "node":
        setMethodsList(getNodeMethods(event.target.value));

      case "apiName":
        setApiNameInputVal(event.target.value);
        if (event.target.value) {
          setApiNameError(false);
        } else {
          setApiNameError(true);
        }
        break;

      case "description":
        setDescriptionInputVal(event.target.value);
        if (event.target.value) {
          setDescriptionError(false);
        } else {
          setDescriptionError(true);
        }
        break;
    }
  };

  const handelAlertView = () => {
    setAlertOpen(!alertOpen);
  };

  const handelAlertClose = () => {
    setAlertOpen(!alertOpen);
  };
  const handelFileReading = () => {
    setUploadedFileContent(reader.result);
  };
  function addProductHandler() {
    if (apiNameInputVal === "Select API Name") {
      setApiNameError(true);
    } else {
      setApiNameError(false);
    }
    if (descriptionInputVal === "") {
      setDescriptionError(true);
    } else {
      setDescriptionError(false);
    }

    if (
      uploadedFileContent != null &&
      nodeInputVal != descriptionInputVal &&
      apiNameInputVal != "Select API Name"
    ) {
      setShowLoader(true);
      setNodeInputVal("Select Node");
      setApiNameInputVal("Select API Name");
      setDescriptionInputVal("");
      if (!isUpdate) {
        axios({
          method: "post",
          url: "" + API.baseURL + "nodeApis/AddNodeApi",
          data: {
            nodeId: Number(selectedNode.id),
            apiName: String(apiNameInputVal),
            description: String(descriptionInputVal),
            binaryXml: uploadedFileContent,
          },
        })
          .then(function (response) {
            console.log(response);
            setShowLoader(false);
            setAlertOpen(true);
            setAlertMessage("Node API's Added Successfully");
            props.loadAllNodes();
          })
          .catch(function (error) {
            setShowLoader(false);
            console.log(error);
          });
      } else {
        axios
          .put(`${API.baseURL}nodeApis/${apiDetailsId}`, {
            id: apiDetailsId,
            apiName: apiNameInputVal,
            description: descriptionInputVal,
            nodeId: nodeInputVal,
          })
          .then(function (response) {
            setShowLoader(false);
            setAlertOpen(true);
            setAlertMessage("Parameter Details Updated Successfully");
            props.onHideCreatFormHandler();
            props.loadAllNodes();
          })
          .catch(function (error) {
            setShowLoader(false);
          });
      }
    }
  }
  const onFileChange = (event) => {
    setFilename(event.target.files[0].name);
    reader = new FileReader();
    reader.onload = handelFileReading;
    reader.readAsText(event.target.files[0]);
  };
  //export default function FullWidthGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AlertDialog
        handelAlertView={handelAlertView}
        handelAlertClose={handelAlertClose}
        alertOpen={alertOpen}
        alertMessage={alertMessage}
      />

      <Grid className={classes.gridContainer} container spacing={1}>
        <Grid item style={{ width: "25%" }}>
          <div className={classes.inputLabel}>Node</div>
          <Paper className={clsx(classes.paper, classes.selectInputPaper)}>
            <select
              value={nodeInputVal}
              className={classes.select}
              onChange={(event) => onChangeInputHandler(event, "node")}
            >
              <option
                value={selectedNode.id}
                data-id={selectedNode.name}
                key={selectedNode.id}
              >
                {selectedNode.name}
              </option>
            </select>
          </Paper>
          {nodeError ? (
            <div className={classes.errorMessage}>Node is required</div>
          ) : (
            ""
          )}
        </Grid>

        <Grid item style={{ width: "25%" }}>
          <FormControl className={clsx(classes.textField)} variant="outlined">
            <div className={classes.inputLabel}>API Name</div>
            <OutlinedInput
              value={apiNameInputVal}
              className={classes.outlinedInputStyle}
              id="outlined-adornment-weight"
              onChange={(event) => onChangeInputHandler(event, "apiName")}
              aria-describedby="outlined-weight-helper-text"
              labelWidth={0}
            />
          </FormControl>
          {apiNameError ? (
            <div className={classes.errorMessage}>API Name is required</div>
          ) : (
            ""
          )}
        </Grid>

        <Grid item style={{ width: "25%" }}>
          <FormControl className={clsx(classes.textField)} variant="outlined">
            <div className={classes.inputLabel}>Description</div>
            <OutlinedInput
              value={descriptionInputVal}
              className={classes.outlinedInputStyle}
              id="outlined-adornment-weight"
              onChange={(event) => onChangeInputHandler(event, "description")}
              aria-describedby="outlined-weight-helper-text"
              labelWidth={0}
            />
          </FormControl>
          {descriptionError ? (
            <div className={classes.errorMessage}>Description is required</div>
          ) : (
            ""
          )}
        </Grid>

        <Grid item style={{ width: "25%" }}>
          <FormControl className={clsx(classes.textField)} variant="outlined">
            <div className={classes.inputLabel}>Upload</div>
            <input
              accept=".txt,xml"
              onChange={onFileChange}
              className={classes.input}
              style={{ display: "none" }}
              id="raised-button-file"
              multiple
              type="file"
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="raised"
                component="span"
                className={classes.button}
                style={{ textAlign: "left" }}
              >
                {fileName ? fileName : "Browse to upload"}
              </Button>
            </label>
          </FormControl>
        </Grid>

        {/* <Grid
          item
          style={{
            width: "25%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "2%",
          }}
        >
          <Button
            variant="contained"
            className={classes.createEntitiesActionButtons}
            onClick={props.onHideCreatFormHandler}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => addProductHandler()}
            className={classes.createEntitiesActionButtons}
            style={{ background: Colors.deloitte_green, color: "white" }}
          >
            {isUpdate ? "Update" : "Save"}
          </Button>
        </Grid> */}
        <Grid item style={{ width: "100%", display: 'flex', justifyContent: 'start', marginTop: '2%' }}>
          <Button variant="outlined" className={clsx(classes.createEntitiesActionButtons, classes.cancelButton, 'mx-2')} onClick={props.onHideCreatFormHandler}>Cancel</Button>
          <Button variant="contained" onClick={() => addProductHandler()} className={clsx(classes.createEntitiesActionButtons, classes.addButton, 'mx-2')} style={{ background: Colors.deloitte_green, color: 'white' }}>
            Add
          </Button>
        </Grid>

      </Grid>
    </div>
  );
}
export default CreateNode;
