import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import API from '../../varaibles/baseApi';
import { Paper, Button, Checkbox, TextField, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AlertDialog from '../../components/AlertDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
import Loader from '../../components/Loader';

import TextInput from "../../components/input/input";

import axios from 'axios';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function createGtvData(rowID, supplierName, productName, size, packageItem, quantity) {
    return { rowID, supplierName, productName, size, packageItem, quantity };
}

function apiGtvData(rowID, FromInventory_ID, ToInventory_ID, Date, Note, Supplier, Product_ID, Quantity, Package_ID) {
    return { rowID, FromInventory_ID, ToInventory_ID, Date, Note, Supplier, Product_ID, Quantity, Package_ID };
}

const headCells = [
    {
        id: "supplierName",
        numeric: false,
        disablePadding: true,
        label: "Supplier",
    },
    {
        id: "productName",
        numeric: true,
        disablePadding: false,
        label: "Product",
    },
    {
        id: "size",
        numeric: true,
        disablePadding: false,
        label: "Size",
    },
    {
        id: "packageItem",
        numeric: true,
        disablePadding: false,
        label: "Package",
    },
    { id: "quantity", numeric: true, disablePadding: false, label: "Quantity" },
    {
        id: "",
        numeric: true,
        disablePadding: false,
        label: "",
    },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHeaderStyle}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding="default"
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ fontWeight: 'bold' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >

                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {

    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    inputPaper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
        '& tr:nth-child(even)': {
            backgroundColor: '#fafafa',
        },
        '& tr:hover': {
            backgroundColor: '#fafafa',
        }
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    tableHeaderStyle: {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        background: '#fafafa'
    },
    TablePaginationStyle: {
        "& p:first-of-type": {
            display: "none",
        },
        "& .MuiTablePagination-selectRoot": {
            display: "none",
        },
    },
    sizeCellContainer: {
        textAlign: 'center',
        background: '#8498c9',
        color: 'white',
        padding: '4px',
        width: '70%',
        borderRadius: '4px',
    },
    tableContainer: {
        maxHeight: 260,
    },
    sectionTitle: {
        textAlign: 'left',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: '1%'
    },
    removeBtn: {
        background: '#b40100',
        textTransform: 'none',
        color: 'white'
    },
    transactionActionsContainer: {
        textAlign: 'right',
        marginTop: '3%'
    },
    button: {
        lineHeight: 1.43,
        width: '20%',
        color: "#005074",
        borderColor: "#005074",
        textTransform: "none",
        fontWeight: 'bold',
        fontFamily: 'Montserrat'
    },
}));


const UpdateGroupView = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const [alertMessage, setAlertMessage] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);

    const [showLoader, setShowLoader] = useState(false);

    const [confirmMessage, setConfirmMessage] = useState("");
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [removeSuccess, setRemoveItemSuccess] = useState();

    const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false);

    const [groupNameVal, setGroupNameVal] = useState("");

    const [rolesOptionsList, setRolesOptionsList] = useState([]);
    const [usersOptionsList, setUsersOptionsList] = useState("");
    const [selectedRolesList, setSelectedRolesList] = useState([]);
    const [selectedUsersList, setSelectedUsersList] = useState([]);
    const [groupID, setGroupID] = useState("");
    const [groupName, setGroupName] = useState("");

    useEffect(() => {
        setGroupID(props.groupID);
        setGroupName(props.groupName);
        axios
            .get(
                "" + API.baseURL + "AccessGroup/Roles"
            )
            .then((response) => {
                setRolesOptionsList(response.data);
                setSelectedRolesList(props.rolesList);
            });

        axios
            .get(
                "" + API.baseURL + "AccessUsers/accessuserslist"
            )
            .then((response) => {
                setUsersOptionsList(response.data);
                setSelectedUsersList(props.usersList);
            });
    }, []);


    const handelAlertView = () => {
        setAlertOpen(!alertOpen);
    }

    const handelAlertClose = () => {
        setAlertOpen(!alertOpen);
        if (updatedSuccessfully) {
            props.handelSelectedView("all")
        }
    }

    const handelDisagreeConfirm = () => {
        setConfirmOpen(!confirmOpen);
    }


    const submitGTV = () => {
        console.log(groupID);
        console.log(groupName);
        console.log(selectedRolesList);
        console.log(selectedUsersList);

        if (selectedRolesList.length > 0) {
            setShowLoader(true);

            axios({
                method: 'put',
                url: "" + API.baseURL + "AccessGroup",

                data: {
                    ID: groupID,
                    adminUsers: selectedUsersList,
                    adminRoles: selectedRolesList
                }

            }).then(function (response) {
                console.log(response);
                setShowLoader(false);
                setAlertOpen(true);
                setAlertMessage("Updated Successfully");
                setUpdatedSuccessfully(true);

            }).catch(function (error) {
                console.log(error);
            });


        } else {
            setAlertOpen(true);
            setAlertMessage("You Need To Add Group Data First");
        }
    }

    return (
        <div>
            <Loader showLoader={showLoader} />
            <div className={classes.sectionTitle}>
                Update Access Group
            </div>

            <AlertDialog handelAlertView={handelAlertView} handelAlertClose={handelAlertClose} alertOpen={alertOpen} alertMessage={alertMessage} />

            <ConfirmDialog handelDisagreeConfirm={handelDisagreeConfirm} handelAgreeConfirm={removeSuccess} confirmOpen={confirmOpen} confirmMessage={confirmMessage} />

            <div className={classes.sectionTitle}>
                Group Access Roles
            </div>

            <Autocomplete
                id="combo-box-demo"
                multiple
                disableCloseOnSelect
                value={selectedRolesList}
                options={rolesOptionsList}
                getOptionLabel={option => option.role}
                getOptionSelected={(option, value) => value.id === option.id}
                style={{ width: '95%' }}
                renderInput={params => (
                    <TextField {...params} variant="outlined" label="Access Roles List" />
                )}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8, color: "#007fdf" }}
                            checked={selected}
                        />
                        {option.role}
                    </React.Fragment>
                )}
                onChange={(_, selectedOptions) => setSelectedRolesList(selectedOptions)}
            />

            <div className={classes.sectionTitle} style={{ marginTop: '3%' }}>
                Group Users (Optional)
            </div>

            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={usersOptionsList}
                value={selectedUsersList}
                disableCloseOnSelect
                getOptionLabel={(option) => option.accessUser_Name}
                getOptionSelected={(option, value) => value.id === option.id}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8, color: "#007fdf" }}
                            checked={selected}
                        />
                        {option.accessUser_Name}
                    </React.Fragment>

                )}
                style={{ width: '95%' }}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Users List" />
                )}

                onChange={(_, selectedOptions) => setSelectedUsersList(selectedOptions)}
            />

            <div className={classes.transactionActionsContainer}>
                <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={() => props.handelSelectedView("all")}
                >
                    Close
                </Button>

                <Button
                    variant="contained"
                    className={classes.button}
                    style={{ background: '#005074', marginLeft: '1%', color: 'white' }}
                    onClick={submitGTV}
                >
                    Save Group
                </Button>
            </div>

        </div>
    );
}


export default UpdateGroupView;



const top100Films = [
    { title: 'The Shawshank Redemption', id: 1 },
    { title: 'The Godfather', id: 2 },
    { title: 'The Godfather: Part II', id: 3 },
    { title: 'The Dark Knight', id: 4 },
    { title: '12 Angry Men', id: 5 },
    { title: "Schindler's List", id: 6 },
    { title: 'Pulp Fiction', id: 7 },
    { title: 'The Good, the Bad and the Ugly', id: 9 },
    { title: 'Fight Club', id: 1999 },
    { title: 'Forrest Gump', id: 12 },
    { title: 'Inception', id: 13 },
    { title: 'Goodfellas', id: 16 },
    { title: 'The Matrix', id: 17 },
    { title: 'Seven Samurai', id: 18 },
];